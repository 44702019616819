import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from "framer-motion";
import { Link } from "react-router-dom";

import SpecificCar from '../media/SpecificCar.png'
import AWDIcon from '../media/AWDIcon.png'
import Transmission from '../media/Transmission.png'
import Engine from '../media/Engine.png'
import Gasoline from '../media/Gasoline.png'
import MPG from '../media/MPG.png'
import Seats from '../media/Seat.png'

import leftArrow from '../media/left-arrow-2.png'
import rightArrow from '../media/right-arrow-2.png'

const Main = (props) => {

    const icons = ['', '', AWDIcon, Transmission, Engine, Gasoline, MPG, Seats]

    const highlightedFeatures = ['interior_color', 'exterior_color', 'drivetrain','transmission','engine','fuel','mpg','total_seats']
    const overrideTitles = ['Interior Color', 'Exterior Color', '', '', '', '', '', '']

    const [selectedPhoto, setSelectedPhoto] = useState(0)

    const handleImageChange = (change) => {
        console.log('length: ' + props.car.photos.length)
        console.log('newVal: ' + (selectedPhoto + change))
        if (selectedPhoto + change < 0)
        {
            setSelectedPhoto(0)
        }
        else
        {
            if ((selectedPhoto + change) >= props.car.photos.length)
            {
                setSelectedPhoto(props.car.photos.length-1)
            }
            else
            {
                setSelectedPhoto((selectedPhoto) => selectedPhoto + change)
            }
        }
    }

    return (
        <div style={{display: 'flex', flexDirection: window.innerWidth < 1360 ? 'column':'row', height: 'auto', fontFamily: 'inter', color: window.colors.primary, ...props.style, width: window.innerWidth < 1500 && '100%'}}>
            <div className='noSelect' style={{display: 'flex', flexDirection: 'column'}}>
                <img src={'https://cdn.autovitus.com/cars/' + props?.car?.photos?.[selectedPhoto]} style={{width: window.innerWidth < 1360 ? '80%':'710px', borderRadius: '20px', margin: window.innerWidth < 1360 && 'auto', height: '355px', objectFit: 'cover', border: '1px solid ' + window.colors.border}}></img>
                <div style={{display: 'flex', flexDirection: 'row', maxWidth: '710px', width: '95%', overflow: 'hidden', alignItems: 'center', position: 'relative', marginLeft: window.innerWidth < 1360 && 'auto', marginRight: window.innerWidth < 1360 && 'auto', marginTop: '10px'}}>
                    <img onClick={() => handleImageChange(-1)} src={leftArrow} style={{position: 'absolute', backgroundColor: window.colors.white, borderRadius: '100px', left: '10px', cursor: 'pointer', opacity: '60%'}}></img>
                    <img onClick={() => handleImageChange(1)} src={rightArrow} style={{position: 'absolute', backgroundColor: window.colors.white, borderRadius: '100px', right: '10px', cursor: 'pointer', opacity: '60%'}}></img>
                    {(() => {
                        const photos = [];
                        const start = Math.max(0, selectedPhoto - 3); // Start from max(0, selectedPhoto - 3)
                        const end = Math.min(props?.car?.photos?.length, selectedPhoto + 6); // End at min(photos.length, selectedPhoto + 4)

                        // Using a for loop to iterate through the photos within the selected range
                        for (let i = start; i < end; i++) {
                            photos.push(
                                <img key={i} onClick={() => setSelectedPhoto(i)} src={'https://cdn.autovitus.com/cars/' + props.car.photos[i]} style={{width: '112px', marginRight: '10px', borderRadius: '10px', border: i === selectedPhoto ? '1px solid ' + window.colors.primary : '1px solid ' + window.colors.border, cursor: 'pointer', height: '86px', objectFit: 'cover'}}/>
                            );
                        }
                        return photos;  
                    })()}
                </div>
            </div>
            <div style={{display: 'flex', flexDirection: 'column'}}>
                <div style={{display: 'flex', flexDirection: window.innerWidth < 1360 ? 'column':'row'}}>
                    <div style={{display: 'flex', flexDirection: 'column', marginLeft: '50px', textAlign: window.innerWidth < 1360 && 'center', marginTop: window.innerWidth < 1360 && '30px', marginLeft: window.innerWidth < 1360 ? 'auto':'50px', marginRight: window.innerWidth < 1360 && 'auto'}}>
                        <span style={{fontWeight: '700', fontSize: '24px'}}>{props?.car?.year} {props?.car?.brand} {props?.car?.model}</span>
                        <span style={{fontWeight: '500'}}>{props?.car?.trim}</span>
                        <span style={{fontSize: '20px', fontWeight: '700', textDecoration: 'line-through', marginTop: '30px', color: window.colors.muted}}>${window.addCommas(props?.car?.msrp)}</span>
                        <span style={{fontSize: '32px', fontWeight: '700', marginTop: '5px'}}>${window.addCommas(props?.car?.discounted_msrp)}</span>
                        <div style={{backgroundColor: window.colors.primary, color: window.colors.white, padding: '3px 8px 3px 8px', borderRadius: '100px', textAlign: 'center', marginTop: '10px', width: '100px', marginLeft: window.innerWidth < 1360 && 'auto', marginRight: window.innerWidth < 1360 && 'auto'}}>
                            <span>{(((props?.car?.msrp - props?.car?.discounted_msrp) / props?.car?.msrp)*100).toFixed(0)}% Off</span>
                        </div>
                    </div>
                    <motion.a href="#vehiclePurchase" whileHover={window.bAnim} style={{backgroundColor: window.colors.primary, color: window.colors.white, padding: '15px 25px 15px 25px', border: 'none', borderRadius: '5px', cursor: 'pointer', fontWeight: '500', fontFamily: 'inter', textDecoration: 'none', textAlign: 'center', marginLeft: 'auto', height: '20px', marginRight: window.innerWidth < 1360 && 'auto', marginTop: window.innerWidth < 1360 && '20px'}}>Secure This Deal</motion.a>
                </div>
                <div style={{display: 'grid', gridTemplateColumns: window.innerWidth < 850 ? (window.innerWidth < 500 ? 'repeat(1, 1fr)':'repeat(2, 1fr)'):'repeat(4, 1fr)', gap: '13px', marginLeft: 'auto', marginRight: window.innerWidth < 1360 && 'auto', marginTop: 'auto', paddingLeft: window.innerWidth > 1360 && '50px', paddingRight: '0px', paddingBottom: '0px', paddingTop: window.innerWidth < 1360 && '20px'}}>
                    {icons.map((item, index) => {
                        return (
                            <motion.div className='center' whileHover={{scale: 1.05}} style={{display: 'flex', flexDirection: 'column', textAlign: 'center', alignItems: 'center', borderRadius: '5px', padding: '10px', boxShadow: '0px 0px 10px 1px rgba(0,0,0,0.1)'}}>
                                {index <= 1 && <div style={{width: '25px', height: '25px', backgroundColor: index === 0 ? props?.car?.interior_color:props?.car?.exterior_color, borderRadius: '100px', marginTop: '10px', boxShadow: '0px 0px 5px 1px rgba(0,0,0,0.3)'}}></div>}
                                <img src={icons[index]} style={{marginTop: '10px'}}></img>
                                <span style={{marginTop: '15px'}}>{overrideTitles[index] !== '' ? overrideTitles[index]:(index === 6 ? props?.car?.city_mpg + ' City  / ' + props?.car?.highway_mpg + ' Highway':(index === 7 ? props?.car?.[highlightedFeatures[index]] + ' Seats':props?.car?.[highlightedFeatures[index]]))}</span>
                            </motion.div>
                        )
                    })}
                </div>
            </div>
        </div>
    );
}

export default Main;
