import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';

import featuredCar from '../media/featuredCar.png';
import downArrow from '../media/down-arrow.png';

import ShopFilters from '../elements/ShopFilters'
import ShopSearch from '../elements/ShopSearch'

const Main = (props) => {

    const [showFilters, setShowFilters] = useState(false)

    const [selectedOptions, setSelectedOptions] = useState([])

    const [page, setPage] = useState(1)

    return (
        <div style={{ display: 'flex', flexDirection: 'row', fontFamily: 'inter', color: window.colors.primary, ...props.style, marginTop: '50px' }}>
            <ShopFilters selectedOptions={selectedOptions} setSelectedOptions={setSelectedOptions} showFilters={showFilters} page={page} setPage={setPage}></ShopFilters>
            <ShopSearch selectedOptions={selectedOptions} setSelectedOptions={setSelectedOptions} style={{marginLeft: window.innerWidth > 800 ? '20px':'auto', width: window.innerWidth < 800 ? '90%':'100%', marginRight: 'auto', paddingRight: window.innerWidth > 800 && '20px'}} showFilters={showFilters} setShowFilters={setShowFilters} page={page} setPage={setPage}></ShopSearch>
        </div>
    );
};

export default Main;
