import React from 'react';
import BMW from '../media/BMW.png';
import Mercedes from '../media/MercedesBenz.png';
import Audi from '../media/Audi.png';
import Toyota from '../media/Toyota.png';
import Honda from '../media/Honda.png';
import Ford from '../media/Ford.png';
import Chevrolet from '../media/Chevy.png';
import Nissan from '../media/Nissan.png';
import Volkswagen from '../media/Volkwagen.png';
import Hyundai from '../media/Hyundai.png';
import Kia from '../media/Kia.png';
import Mazda from '../media/Mazda.png';
import Lexus from '../media/Lexus.png';
import Subaru from '../media/Subaru.png';
import Jeep from '../media/Jeep.png';
import Tesla from '../media/Tesla.png';

import {motion} from 'framer-motion'
import { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'

const Main = (props) => {

    const brands = [
        { name: 'BMW', logo: BMW },
        { name: 'Mercedes', logo: Mercedes },
        { name: 'Audi', logo: Audi },
        { name: 'Toyota', logo: Toyota },
        { name: 'Honda', logo: Honda },
        { name: 'Ford', logo: Ford },
        { name: 'Chevrolet', logo: Chevrolet },
        { name: 'Nissan', logo: Nissan },
        { name: 'Volkswagen', logo: Volkswagen },
        { name: 'Hyundai', logo: Hyundai },
        { name: 'Kia', logo: Kia },
        { name: 'Mazda', logo: Mazda },
        { name: 'Lexus', logo: Lexus },
        { name: 'Subaru', logo: Subaru },
        { name: 'Jeep', logo: Jeep },
        { name: 'Tesla', logo: Tesla }
    ];

    const [gridTemplate, setGridTemplate] = useState('1fr 1fr 1fr 1fr');

    useEffect(() => {
        const updateGrid = () => {
            if (window.innerWidth < 800) {
                setGridTemplate('repeat(1, 305px)');
            } else if (window.innerWidth < 1550) {
                setGridTemplate('repeat(2, 305px)');
            } else {
                setGridTemplate('repeat(4, 305px)');
            }
        };
        window.addEventListener('resize', updateGrid);
        updateGrid();
        return () => window.removeEventListener('resize', updateGrid);
    }, []);

    return (
        <div style={{ display: 'flex', flexDirection: 'column', width: '100%', fontFamily: 'inter', color: window.colors.primary, ...props.style, textAlign: 'center' }}>
            <span style={{ fontSize: '32px', fontWeight: '700', letterSpacing: '-1px' }}>Shop by your preferences</span>
            <div style={{ display: 'grid', gridTemplateColumns: gridTemplate, gridGap: '30px', justifyContent: 'center', marginTop: '30px' }}>
                {brands.map((brand, index) => (
                    <Link to={'/shop?filter=' + brand.name + '&field=make'} style={{textDecoration: 'none', color: window.colors.primary, fontWeight: '500'}}><motion.div whileHover={{scale: 1.03}} key={index} style={{ width: window.innerWidth < 400 ? '90%':'100%', height: '60px', display: 'flex', justifyContent: 'center', alignItems: 'center', border: '0px solid ' + window.colors.border, borderRadius: '8px', cursor: 'pointer', marginTop: '20px', boxShadow: '0px 0px 10px 1px rgba(0,0,0,0.05)', marginLeft: window.innerWidth < 400 && 'auto', marginRight: window.innerWidth < 400 && 'auto' }}>
                        <img src={brand.logo} alt={brand.name} style={{ width: '30px' }} />
                        <span style={{marginLeft: '10px'}}>{brand.name}</span>
                    </motion.div></Link>
                ))}
            </div>
        </div>
    );
}

export default Main;
