// src/analytics.js
import ReactGA from 'react-ga4';

// Replace with your Measurement ID
const MEASUREMENT_ID = 'G-WG2QGNC4ZR'; 

export const initializeGA = () => {
  ReactGA.initialize(MEASUREMENT_ID);
};

export const logPageView = (path) => {
  ReactGA.send({ hitType: 'pageview', page: path });
};