import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from "framer-motion";
import { Link } from "react-router-dom";

import FeatureIcon from '../media/FeatureIcon.png'

import comfort from '../media/comfort.png'
import entertainment from '../media/entertainment.png'
import safety from '../media/safety.png'
import warranty from '../media/warranty.png'

import Modal from 'react-modal'

const Main = (props) => {

    const categories = ['comfort', 'entertainment', 'safety', 'warranty']
    const icons = [comfort, entertainment, safety, warranty]

    const [keyFeatures, setKeyFeatures] = useState([])
    const [showSticker, setShowSticker] = useState(false)

    useEffect(() => {
        setKeyFeatures([props.car.comfort_features, props.car.entertainment_features, props.car.safety_features, props.car.warranty])
    },[props.car])

    function capitalizeFirstLetter(str) {
        if (!str) return str; // Return if the string is empty or null
        return str.charAt(0).toUpperCase() + str.slice(1);
    }
    
    const [imageError, setImageError] = useState(false);

    const handleError = () => {
      setImageError(true);
    };

    return (
        <div style={{display: 'flex', flexDirection: 'column', height: 'auto', fontFamily: 'inter', color: window.colors.primary, ...props.style, width: window.innerWidth < 1500 && '95%', marginLeft: window.innerWidth < 1500 && 'auto', marginRight: window.innerWidth < 1500 && 'auto'}}>
            
            <Modal isOpen={showSticker} onRequestClose={() => setShowSticker(false)} style={{ overlay: { backgroundColor: 'rgba(0, 0, 0, 0.3)' }, content: { top: '50%', left: '50%', right: 'auto', bottom: 'auto', marginRight: '-50%', transform: 'translate(-50%, -50%)', padding: '0px 20px 5px 20px', width: '80%', maxWidth: '500px', borderRadius: '10px', border: '1px solid #ccc' } }}>
                <div style={{display: 'flex', flexDirection: 'row'}}>
                    <h2 style={{color: window.colors.primary }}>{props?.car?.year} {props?.car?.brand} {props?.car?.model} sticker</h2>
                </div>
                <div style={{fontSize: '20px', color: window.colors.primary, fontWeight: '500', marginBottom: '10px', borderTop: '1px solid ' + window.colors.primary, paddingTop: '10px'}}><span></span></div>
                {!imageError ? <img style={{maxWidth: '1200px'}} onError={handleError} src={'https://cdn.autovitus.com/cars/' + props.car.sticker}></img>:<span>No Sticker Available</span>}
            </Modal>
            
            <div style={{display: 'flex', flexDirection: window.innerWidth < 480 ? 'column':'row', margin: window.innerWidth < 1550 && 'auto', textAlign: 'center'}}>
                <span style={{fontSize: '32px', fontWeight: '700'}}>Key Features</span>
                <span onClick={() => setShowSticker(true)} style={{marginLeft: window.innerWidth <= 560 ? '0px':'15px', backgroundColor: 'transparent', border: 'none', outline: 'none', color: window.colors.link, marginTop: '15px', fontSize: '16px', fontWeight: '500', fontFamily: 'inter', cursor: 'pointer', textDecoration: 'none'}}>View Vehicle Sticker</span>
            </div>
            <div style={{display: 'flex', flexDirection: 'row', flexWrap: 'wrap'}}>
            {keyFeatures?.map((item, index) => {
                return (
                    <div style={{display: 'flex', flexDirection: 'column', marginLeft: index === 0 ? (window.innerWidth < 950 ? 'auto' : '0px') : 'auto', marginRight: window.innerWidth < 950 ? 'auto' : '10px', marginTop: window.innerWidth < 950 ? '50px' : '20px', width: '300px', alignItems: window.innerWidth < 950 && 'center'}}>
                        <img src={icons[index]} style={{width: '40px'}}></img>
                        <span style={{fontWeight: '500', fontSize: '24px', marginTop: '30px', marginBottom: '15px'}}>{capitalizeFirstLetter(categories[index])}</span>
                        {item?.map((item2, index) => {
                            return (
                                <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: '15px'}}>
                                    <img src={FeatureIcon} style={{width: '12px', height: '13px', marginRight: '10px'}}></img>
                                    <span>{capitalizeFirstLetter(item2.name)}</span>
                                </div>
                            )
                        })}
                    </div>
                )
            })}
            </div>
        </div>
    );
}

export default Main;
