import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from "framer-motion";
import { Link } from "react-router-dom";

import downArrow from '../media/down-arrow.png'
import bigArrow from '../media/bigArrow.png'

const Main = (props) => {

    console.log(props)

    const [helpWindow, setHelpWindow] = useState(-1)

    const [gridTemp, setGridTemp] = useState('repeat(5, 1fr)')

    const [openSection, setOpenSection] = useState(null);

    const toggleSection = (section) => {
        setOpenSection(openSection === section ? null : section);
    };

    const [sections, setSections] = useState([
        {
            title: 'Vehicle Cost',
            amount: props?.car?.discounted_msrp,
            items: [
                {
                    title: 'Base Price',
                    amount: (props?.car?.options?.reduce((total, item) => total + item.cost, props?.car?.base_msrp) + props?.car?.destination_charge || 0),
                },
                ...(props?.car?.options?.map((item) => ({
                    title: item.title,
                    amount: item.cost,
                })) || [])
            ]
        },
        {
            title: 'Discounts',
            amount: 0,
            items: [
                ...(props?.car?.discounts?.map((item) => ({
                    title: item.title,
                    amount: item.cost,
                })) || [])
            ]
        },
        {
            title: 'Transactional Fees',
            amount: 0,
            items: [
                {
                    title: 'Tax',
                    amount: props.car.discounted_msrp,
                },
                {
                    title: 'DMV Fee',
                    amount: props.car.base_price,
                },
                {
                    title: 'Doc Fee',
                    amount: 2170,
                },
                {
                    title: 'Tire Fee',
                    amount: 1000
                }
            ]
        },
        {
            title: 'Payment Calculation',
            amount: 0,
            items: [
                {
                    title: 'MSRP',
                    amount: props.car.discounted_msrp,
                },
                {
                    title: 'Discounted MSRP',
                    amount: props.car.base_price,
                },
                {
                    title: 'Residual',
                    amount: 2170,
                },
                {
                    title: 'Total Depreciation',
                    amount: 1000
                },
                {
                    title: 'Term Length',
                    amount: 1000
                },
                {
                    title: 'Down Payment',
                    amount: 1000
                },
                {
                    title: 'Monthly Payment',
                    amount: 1000
                },
            ]
        },
    ])

    const [loanType, setLoanType] = useState('Lease')

    const reverseCalcLeaseTax = () => {
        return props?.car?.residual + Number(props.leaseDown) + (Number(props.leaseMonthly) * props?.car?.lease_term_length) - props?.car?.discounted_msrp - props?.car?.fees?.reduce((total, item) => total + item.cost, 0)
    }

    const reverseCalcFinanceTax = () => {
        return Number(props.financeDown) + (Number(props.financeMonthly) * props?.car?.finance_term_length) - props?.car?.discounted_msrp - props?.car?.fees?.reduce((total, item) => total + item.cost, 0)
    }

    useEffect(() => {
        // Create a new sections array based on the latest props
        const updatedSections = [
            {
                title: 'Vehicle Cost',
                amount: (props?.car?.packages?.reduce((total, item) => total + item.price, 0) + (props?.car?.base_msrp || 0)),
                items: [
                    {
                        title: 'Base Price',
                        amount: props?.car?.base_msrp || 0,
                        help_message: 'This is the base price set by the manufacturer, excluding additional options, destination charges, or fees.'
                    },
                    ...(props?.car?.packages?.map((item) => ({
                        title: item.name,
                        amount: item.price,
                        help_message: 'This is the cost of an optional feature added to enhance the vehicle, such as upgrades in performance, comfort, or technology.'
                    })) || []),
                ],
            },
            {
                title: 'Discounts',
                amount: (props?.car?.discounts?.reduce((total, item) => total + item.price, 0)),
                items: [
                    ...(props?.car?.discounts?.length > 0 
                        ? props?.car?.discounts?.map((item) => ({
                            title: item.name,
                            amount: item.price,
                            help_message: 'This is a discount applied to reduce the overall cost of the vehicle, such as manufacturer incentives or dealership promotions.'
                        })) 
                        : [{ 
                            title: 'No Discounts', 
                            amount: 0, 
                            help_message: 'No discounts have been applied to this vehicle.' 
                        }]
                    ),
                ],
            },
            {
                title: 'Transactional Fees',
                amount: (props?.car?.fees?.reduce((total, item) => total + item.price, props?.car?.tax || 0) + (( props?.car?.lease_charge) || 0)).toFixed(2),
                items: [
                    ...(props?.car?.fees?.map((item) => ({
                        title: item.name,
                        amount: item.price,
                        help_message: 'This is a fee related to the transaction, such as documentation fees, registration, or licensing.'
                    })) || []),
                    {
                        title: 'Lease Charge (Interest)',
                        amount: ( props?.car?.lease_charge),
                        help_message: 'This is a fee (interest) that the bank charges for the loan.'
                    },
                    {
                        title: 'Tax',
                        amount: props.car.tax,
                        help_message: 'This is the estimated tax calculated based on the transaction, varying by loan type (lease or finance).'
                    }
                ],
            },
            {
                title: 'Payment Calculation',
                amount: loanType === 'Lease' 
                    ? (window.addCommas(props.car.lease_monthly) + ' | $' + window.addCommas(props.car.lease_down))
                    : (window.addCommas(props.financeMonthly) + ' | $' + window.addCommas(props.financeDown)),
                items: [
                    {
                        title: 'MSRP',
                        amount: props.car?.msrp || 0,
                        help_message: 'Manufacturer Suggested Retail Price is the price the manufacturer recommends selling the vehicle for.'
                    },
                    {
                        title: 'Total Discounts',
                        amount: -1 * props?.car?.discounts?.reduce((total, item) => total + item.price, 0) || 0,
                        help_message: 'Total Discounts sum up all discounts applied to the MSRP to reduce the vehicle cost.'
                    },
                    {
                        title: 'Discounted MSRP',
                        amount: props.car?.discounted_msrp || 0,
                        help_message: 'The MSRP after subtracting total discounts.'
                    },
                    {
                        title: 'Residual',
                        amount: props.car?.residual || 0,
                        help_message: 'The estimated value of the vehicle at the end of a lease term, affecting the lease payment calculation.'
                    },
                    {
                        title: 'Total Depreciation',
                        amount: props.car?.discounted_msrp - props.car?.residual,
                        help_message: 'The difference between the discounted MSRP and the residual value, representing the depreciation over the lease term.'
                    },
                    {
                        title: 'Fees',
                        amount: true ? (props?.car?.fees?.reduce((total, item) => total + item.price, props?.car?.tax || 0) + (( props?.car?.lease_charge * props?.car?.lease_term_length) || 0)) : 0,
                        help_message: 'The total amount of additional fees associated with the transaction, including registration and other charges.'
                    },
                    {
                        title: 'Tax',
                        amount: true ? props.car.tax : 0,
                        help_message: 'The calculated tax amount based on either lease or finance terms.'
                    },
                    {
                        title: 'Total Cost of ' + loanType,
                        amount: props.car.tax + (props?.car?.fees?.reduce((total, item) => total + item.price, props?.car?.tax || 0) + (( props?.car?.lease_charge * props?.car?.lease_term_length) || 0)) + props.car?.discounted_msrp - props.car?.residual,
                        help_message: 'The total cost of the lease or finance term, calculated as the sum of all payments, fees, and taxes.'
                    },
                    {
                        title: 'Term Length',
                        amount: loanType === 'Lease' ? props?.car?.lease_term_length : props?.car?.finance_term_length,
                        help_message: 'The duration of the loan or lease agreement, measured in months.'
                    },
                    {
                        title: 'Due at Signing',
                        amount: (loanType === 'Lease' ? Number(props.car.lease_down) : Number(props.financeDown)),
                        help_message: 'The total amount due at the start of the lease or loan, including the down payment, fees, and taxes.'
                    },
                    {
                        title: 'Monthly Payment',
                        amount: (loanType === 'Lease' ? Number(props.car.lease_monthly) : Number(props.financeMonthly)),
                        help_message: 'The estimated monthly payment based on the loan or lease terms, including fees, and taxes.'
                    }
                ],
            }
        ];        

        // Update the state with the new sections
        setSections(updatedSections);
    }, [props.car, props.includeTaxFees, loanType, props.leaseMonthy, props.financeMonthly, props.loanType]);

    useEffect(() => {
        if (window.innerWidth < 500)
        {
            setGridTemp('repeat(1, 1fr)')
        }
        else if (window.innerWidth < 800)
        {
            setGridTemp('repeat(2, 1fr)')
        }
        else if (window.innerWidth < 1075)
        {
            setGridTemp('repeat(3, 1fr)')
        }
        else if (window.innerWidth < 1350)
        {
            setGridTemp('repeat(4, 1fr)')
        }
        else
        {
            setGridTemp('repeat(5, 1fr)')
        }
    },[window.innerWidth])

    useEffect(() => {
        console.log(helpWindow)
    },[helpWindow])

    const [taxMessage, setTaxMessage] = useState(false)

    const calculateLeaseValues = (car, taxFees, tax) => {
        const dpAmount = 0.05
        const depreciation = car.discounted_msrp - car.residual
        const leaseCharge = (car.msrp + car.residual)*car.money_factor;
        let fees = props?.car?.fees?.reduce((total, item) => total + item.cost, 0)
        if (taxFees)
        {
            const monthlyPayment = ((depreciation - (car.discounted_msrp * dpAmount)) * (1 + tax) + fees) / car.lease_term_length + leaseCharge;
            props.setLeaseMonthly(monthlyPayment.toFixed(2));
            props.setLeaseDown((car.discounted_msrp * dpAmount).toFixed(2));
        }
        else
        {
            props.setLeaseMonthly((((car.discounted_msrp - car.residual) - (car.discounted_msrp * dpAmount))/car.lease_term_length).toFixed(2))
            props.setLeaseDown((car.discounted_msrp * dpAmount).toFixed(2))
        }
    }

    const calculateFinanceInterest = (car, dpAmount) => {
        const principal = car.discounted_msrp - (car.discounted_msrp * dpAmount);
        const annualInterestRate = car.interest_rate
        const monthlyRate = annualInterestRate / 12;
        const termLength = car.finance_term_length;
    
        // Calculate the monthly payment using the amortized loan formula
        const monthlyPayment = (principal * monthlyRate * Math.pow(1 + monthlyRate, termLength)) /
                               (Math.pow(1 + monthlyRate, termLength) - 1);
    
        // Calculate the total payments over the term
        const totalPayments = monthlyPayment * termLength;
    
        // Calculate the total interest by subtracting the principal from the total payments
        const totalInterest = totalPayments - principal;
        console.log(totalInterest)
        return totalInterest;
    }

    const calculateFinanceValues = (car, taxFees, tax) => {
        const dpAmount = 0.1;
        let fees = car?.fees?.reduce((total, item) => total + item.cost, 0) || 0;
        let interestCharge = calculateFinanceInterest(car, dpAmount);
    
        let monthlyPayment, downPayment;
    
        if (taxFees) {
            monthlyPayment = ((((car.discounted_msrp + interestCharge - (car.discounted_msrp * dpAmount)) * (1 + tax)) + fees) / car.finance_term_length).toFixed(2);
            downPayment = (car.discounted_msrp * dpAmount).toFixed(2);
        } else {
            monthlyPayment = (((car.discounted_msrp - (car.discounted_msrp * dpAmount)) / car.finance_term_length).toFixed(2));
            downPayment = (car.discounted_msrp * dpAmount).toFixed(2);
        }
    
        // Set the values to props
        props.setFinanceMonthly(monthlyPayment);
        props.setFinanceDown(downPayment);
    }

    const handleNoTaxFees = () => {
        if (props.includeTaxFees)
        {
            props.setIncludeTaxFees(false)
            calculateLeaseValues(props.car, false, 0)
            calculateFinanceValues(props.car, false, 0)
            localStorage.setItem('includeTaxFees', false)
        }
        else
        {
            if (localStorage.getItem('tax') !== undefined && localStorage.getItem('tax') !== null)
            {
                props.setIncludeTaxFees(true)
                calculateLeaseValues(props.car, true, (localStorage.getItem('tax') / 100))
                calculateFinanceValues(props.car, true, (localStorage.getItem('tax') / 100))
                localStorage.setItem('includeTaxFees', true)
            }
            else
            {
                localStorage.setItem('includeTaxFees', true)
                props.setTaxFeeModal(true)
            }
        }
    }

    return (
        <div id="priceBreakdown" style={{display: 'flex', flexDirection: 'column', height: 'auto', fontFamily: 'inter', color: window.colors.primary, ...props.style, width: window.innerWidth < 1550 && '95%', marginLeft: window.innerWidth < 1550 && 'auto', marginRight: window.innerWidth < 1550 && 'auto', alignItems: 'center'}}>
            <span style={{fontWeight: '700', fontSize :'32px', textAlign: 'center'}}>Full Price Breakdown</span>
            <div style={{display: 'flex', flexDirection: 'column', marginTop: '30px', textAlign: window.innerWidth < 1050 && 'center'}}>
                {/* <span style={{marginLeft: window.innerWidth < 1050 && 'auto', marginRight: window.innerWidth < 1050 && 'auto', textAlign: window.innerWidth < 1050 && 'center', marginTop: '10px'}}>* - Estimations based on being Registered in NJ with Tier 1 Credit (750+ Credit Score)</span> */}
                <div style={{display: 'flex', flexDirection: 'row', marginTop: '0px', marginLeft: 'auto', marginRight: 'auto'}}>
                    <span className='noSelect' style={{marginLeft: '0px'}}>Includes all Estimated Taxes & Fees</span>
                    {/* <div onClick={() => handleNoTaxFees()} style={{display: 'flex', flexDirection: 'row', cursor: 'pointer', alignItems: 'center', marginTop: window.innerWidth < 900 && '15px', paddingBottom: '15px'}}>
                        <div style={{width: '17px', height: '17px', borderRadius: '3px', border: '1px solid ' + window.colors.primary, backgroundColor: props.includeTaxFees ? window.colors.primary:'', marginLeft: 0}}></div>
                        <span className='noSelect' style={{marginLeft: '10px'}}>Include Estimated Taxes & Fees</span>
                        <div className="center" onMouseEnter={() => setTaxMessage(true)} onMouseLeave={() => setTaxMessage(false)} style={{ fontWeight: '500', fontSize: '16px', backgroundColor: window.colors.primary, marginLeft: 'auto', borderRadius: '100px', color: window.colors.white, width: '20px', height: '20px', cursor: 'pointer', position: 'relative', marginLeft: '10px' }}>
                            <span>?</span>
                            <Tooltip text={'These are approximate estimates as DMV/DOC fees depend on both the state and the dealership.'} isVisible={taxMessage}/>
                        </div>
                    </div> */}
                </div>
            </div>
            <div className='noSelect' onClick={() => ''} style={{width: '70%', borderBottom: '1px solid ' + window.colors.primary, paddingBottom: '15px', alignItems: 'center'}}>
                <span style={{textAlign: 'left', width: '10%', fontSize: '24px', fontWeight: '500'}}>{loanType}</span>
                {/* <img src={bigArrow} style={{ marginLeft: window.innerWidth < 750 ? 'auto' : '15px', width: '18px' }} /> */}
            </div>
            <div style={{ display: 'flex', flexDirection: 'row', width: '70%' }}>
                <div style={{ display: 'grid', gridTemplateColumns: gridTemp, gap: '20px', width: '100%', marginTop: '20px', color: window.colors.white }}>
                    <div style={{ display: 'flex', flexDirection: 'column', borderLeft: window.innerWidth < 650 && '1px solid ' + window.colors.white, backgroundColor: window.colors.primary, padding: '15px', borderRadius: '10px' }}>
                        <div style={{ display: 'flex', flexDirection: 'row', position: 'relative', alignItems: 'center' }}>
                            <span style={{ fontWeight: '500', color: window.colors.white, fontSize: '16px' }}>Monthly</span>
                            <div onMouseEnter={() => setHelpWindow(0)} onMouseLeave={() => setHelpWindow(-1)} className="center" style={{ fontWeight: '500', fontSize: '16px', backgroundColor: window.colors.white, marginLeft: 'auto', borderRadius: '100px', color: window.colors.primary, width: '20px', height: '20px', cursor: 'pointer' }}>
                                <span>?</span>
                                <Tooltip style={{left: window.innerWidth < 900 && '-50px'}} text={'The monthly payment that will be made to the bank every month.'} isVisible={helpWindow === 0} />
                            </div>
                        </div>
                        <span style={{ fontWeight: '300', fontSize: '20px', marginTop: '10px' }}>${window.addCommas(props.car.lease_monthly)}</span>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', borderLeft: window.innerWidth < 650 && '1px solid ' + window.colors.white, backgroundColor: window.colors.primary, padding: '15px', borderRadius: '10px' }}>
                        <div style={{ display: 'flex', flexDirection: 'row', position: 'relative', alignItems: 'center' }}>
                            <span style={{ fontWeight: '500', color: window.colors.white, fontSize: '16px' }}>Due at Signing</span>
                            <div onMouseEnter={() => setHelpWindow(1)} onMouseLeave={() => setHelpWindow(-1)} className="center" style={{ fontWeight: '500', fontSize: '16px', backgroundColor: window.colors.white, marginLeft: 'auto', borderRadius: '100px', color: window.colors.primary, width: '20px', height: '20px', cursor: 'pointer' }}>
                                <span>?</span>
                                <Tooltip style={{left: window.innerWidth < 900 && '-50px'}} text={'The amount of money that will be paid up-front to the dealership.'} isVisible={helpWindow === 1} />
                            </div>
                        </div>
                        <span style={{ fontWeight: '300', fontSize: '20px', marginTop: '10px' }}>${window.addCommas(props.car.lease_down)}</span>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', borderLeft: window.innerWidth < 650 && '1px solid ' + window.colors.white, backgroundColor: window.colors.primary, padding: '15px', borderRadius: '10px' }}>
                        <div style={{ display: 'flex', flexDirection: 'row', position: 'relative', alignItems: 'center' }}>
                            <span style={{ fontWeight: '500', color: window.colors.white, fontSize: '16px' }}>Discount</span>
                            <div onMouseEnter={() => setHelpWindow(2)} onMouseLeave={() => setHelpWindow(-1)} className="center" style={{ fontWeight: '500', fontSize: '16px', backgroundColor: window.colors.white, marginLeft: 'auto', borderRadius: '100px', color: window.colors.primary, width: '20px', height: '20px', cursor: 'pointer' }}>
                                <span>?</span>
                                <Tooltip style={{left: window.innerWidth < 900 && '-50px'}} text={'The total percentage under the MSRP that the vehicle will be purchased for.'} isVisible={helpWindow === 2} />
                            </div>
                        </div>
                        <span style={{ fontWeight: '300', fontSize: '20px', marginTop: '10px' }}>{((props.car.msrp - props.car.discounted_msrp)/props.car.msrp * 100).toFixed(0)}%</span>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', borderLeft: window.innerWidth < 650 && '1px solid ' + window.colors.white, backgroundColor: window.colors.primary, padding: '15px', borderRadius: '10px' }}>
                        <div style={{ display: 'flex', flexDirection: 'row', position: 'relative', alignItems: 'center' }}>
                            <span style={{ fontWeight: '500', color: window.colors.white, fontSize: '16px' }}>Term Length</span>
                            <div onMouseEnter={() => setHelpWindow(3)} onMouseLeave={() => setHelpWindow(-1)} className="center" style={{ fontWeight: '500', fontSize: '16px', backgroundColor: window.colors.white, marginLeft: 'auto', borderRadius: '100px', color: window.colors.primary, width: '20px', height: '20px', cursor: 'pointer' }}>
                                <span>?</span>
                                <Tooltip style={{left: window.innerWidth < 900 && '-50px'}} text={'The length of the loan and how long you will make the monthly payments for.'} isVisible={helpWindow === 3} />
                            </div>
                        </div>
                        <span style={{ fontWeight: '300', fontSize: '20px', marginTop: '10px' }}>{props.car.lease_term_length} Months</span>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', borderLeft: window.innerWidth < 650 && '1px solid ' + window.colors.white, backgroundColor: window.colors.primary, padding: '15px', borderRadius: '10px' }}>
                        <div style={{ display: 'flex', flexDirection: 'row', position: 'relative', alignItems: 'center' }}>
                            <span style={{ fontWeight: '500', color: window.colors.white, fontSize: '16px' }}>{loanType === 'Lease' ? 'Residual':"Loan to Value %"}</span>
                            <div onMouseEnter={() => setHelpWindow(4)} onMouseLeave={() => setHelpWindow(-1)} className="center" style={{ fontWeight: '500', fontSize: '16px', backgroundColor: window.colors.white, marginLeft: 'auto', borderRadius: '100px', color: window.colors.primary, width: '20px', height: '20px', cursor: 'pointer' }}>
                                <span>?</span>
                                <Tooltip style={{left: window.innerWidth < 900 && '-50px'}} text={loanType === 'Lease' ? 'The expected value of the vehicle after the lease. Can be purchased at the end of the lease for this amount.':"The percentage of money put torwards the vehicle based on the discounted MSRP"} isVisible={helpWindow === 4} />
                            </div>
                        </div>
                        <span style={{ fontWeight: '300', fontSize: '20px', marginTop: '10px' }}>{window.addCommas(loanType === 'Lease' ? '$' + props.car.residual:((((props.car.discounted_msrp - props.financeDown)/props.car.discounted_msrp)))*100)}{loanType === 'Finance' && '%'}</span>
                    </div>
                </div>
            </div>
            <div style={{display: 'flex', flexDirection: 'column', width: '70%', boxShadow: '0px 0px 10px 1px rgba(0,0,0,0.1)', borderRadius: '10px', marginTop: '20px', padding: '20px', boxSizing: 'border-box'}}>
                {sections.map((section, index) => (
                    section.title !== 'Transactional Fees' || true ? (
                        <div key={index}>
                            <div onClick={() => toggleSection(index)} style={{ display: 'flex', flexDirection: 'row', padding: '15px', alignItems: 'center', cursor: 'pointer', borderBottom: '1px solid ' + window.colors.primary }}>
                                <span style={{ fontSize: '20px', fontWeight: '500' }}>{section.title}</span>
                                {window.innerWidth > 750 && (<span style={{ marginLeft: 'auto', fontSize: '20px' }}>${window.addCommas(section.amount)}</span>)}
                                <img src={bigArrow} style={{ marginLeft: window.innerWidth < 750 ? 'auto' : '20px' }} />
                            </div>
                            <motion.div initial={{ height: 0, opacity: 0 }} animate={{ height: openSection === index ? 'auto' : 0, opacity: openSection === index ? 1 : 0 }} style={{ overflow: 'hidden', borderBottom: '1px solid ' + window.colors.muted, paddingBottom: '10px'}}>
                                {section?.items.map((option, index2) => (
                                    (option.title !== 'Total Depreciation' || loanType !== 'Finance') && (
                                        <div key={index2} style={{ padding: '10px 15px', fontSize: '16px', display: 'flex', flexDirection: window.innerWidth < 600 ? 'column':'row', overflow:'visible' }}>
                                            <div style={{display: 'flex', flexDirection: 'row', marginTop: '10px'}}>
                                                <span style={{marginRight: '20px', fontSize: '16px', fontWeight: '500'}}>{option.title}</span>
                                                {/* <div onMouseEnter={() => setHelpWindow((index+1000) * (index2+1000))} onMouseLeave={() => setHelpWindow(-1)} className="center" style={{ fontWeight: '500', fontSize: '16px', backgroundColor: window.colors.primary, marginLeft: 'auto', borderRadius: '100px', color: window.colors.white, width: '20px', height: '20px', cursor: 'pointer' }}>
                                                    <span>?</span>
                                                    <Tooltip style={{left: '-400px'}} text={option.help_message} isVisible={helpWindow === ((index+1000) * (index2+1000))}/>
                                                </div> */}
                                            </div>
                                            <span style={{marginLeft: window.innerWidth > 600 && 'auto', marginTop: window.innerWidth < 600 ? '0px':'10px'}}>{option.title !== 'Term Length' && "$"}{window.addCommas(option.amount?.toFixed(2))}</span>
                                        </div>
                                    )
                                ))}
                            </motion.div>
                        </div>
                    ) : null
                ))}
            </div>
        </div>
    );
}

const Tooltip = ({ text, isVisible, style }) => (
    <AnimatePresence>
        {isVisible && (
            <motion.div
                initial={{ opacity: 0, y: -10 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -10 }}
                transition={{ duration: 0.2 }}
                style={{
                    position: 'absolute',
                    backgroundColor: 'white',
                    color: 'black',
                    padding: '10px',
                    borderRadius: '5px',
                    boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
                    zIndex: 150,
                    width: '300px',
                    left: '0px',
                    top: 10,
                    fontWeight: 400,
                    ...style,
                }}
            >
                {text}
            </motion.div>
        )}
    </AnimatePresence>
);

export default Main;
