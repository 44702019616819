import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from "framer-motion";
import { Link } from "react-router-dom";

import Process1 from '../media/process1.png'
import Process2 from '../media/process2.png'
import Process3 from '../media/process3.png'
import Process4 from '../media/process4.png'

const Main = (props) => {

    const [purchaseStage, setPurchaseStage] = useState(0)

    return (
        <div style={{display: 'flex', flexDirection: 'column', width: '100%', height: 'auto', fontFamily: 'inter', color: window.colors.primary, marginLeft: window.innerWidth < 900 && 'auto', marginRight: window.innerWidth < 900 && 'auto', marginTop: '50px', ...props.style}}>
            <div style={{display: 'flex', flexDirection: window.innerWidth < 480 ? 'column':'row', margin: window.innerWidth < 1550 && 'auto', textAlign: 'center'}}>
                <span style={{fontSize: '32px', fontWeight: '700', letterSpacing: '-1px'}}>Purchasing Process</span>
                <Link to='/the-process' style={{marginLeft: window.innerWidth <= 738 ? '0px':'15px', backgroundColor: 'transparent', border: 'none', outline: 'none', color: window.colors.link, marginTop: '15px', fontSize: '16px', fontWeight: '500', fontFamily: 'inter', cursor: 'pointer', textDecoration: 'none'}}>Learn More</Link>
            </div>
            <span style={{marginTop: '15px', fontWeight: '400', color: window.colors.muted, fontSize: '20px', width: '80%', textAlign: window.innerWidth < 1550 && 'center', marginLeft: window.innerWidth < 1550 && 'auto', marginRight: window.innerWidth < 1550 && 'auto'}}>We will never change the price after you agree to a sale.</span>
            <motion.div onMouseLeave={() => setPurchaseStage(0)} style={{display: 'flex', flexDirection: window.innerWidth <= 738 ? 'column':'row', flexWrap: window.innerWidth > 738 && 'wrap', marginTop: '50px', marginLeft: window.innerWidth < 1550 && 'auto', marginRight: window.innerWidth < 1550 && 'auto', textAlign: window.innerWidth <= 738 && 'center'}}>
                <motion.div onMouseEnter={() => setPurchaseStage(1)} style={{display: 'flex', flexDirection: 'column', marginRight: 'auto', marginTop: window.innerWidth < 1550 && '40px', marginLeft: window.innerWidth < 1550 ? (window.innerWidth <= 738 ? "0px":'20px'):'0px', border: '0px solid ' + window.colors.border, borderRadius: '10px', alignItems: 'center', maxWidth: '270px', width: '100%', boxShadow: '0px 0px 10px 1px rgba(0,0,0,0.05)'}}>
                    <div style={{display: 'flex', flexDirection: 'row', paddingTop: '10px', marginBottom: '30px'}}>
                        <div className='center' style={{width: '31px', height: '31px', backgroundColor: window.colors.primary, borderRadius: '100px'}}>
                            <span style={{color: window.colors.white, fontSize: '20px', fontWeight: '500'}}>1</span>
                        </div>
                        <span style={{fontWeight: '700', fontSize: '20px', marginTop: '5px', marginLeft: '15px'}}>Secure The Deal</span>
                    </div>
                    <img src={Process1} style={{width: '44px', margin: window.innerWidth <= 738 && 'auto'}}></img>
                    <div className='center' style={{backgroundColor: window.colors.white, color: window.colors.primary, textAlign: 'center', width: '100%', marginTop: '30px', borderBottomLeftRadius: '10px', borderBottomRightRadius: '10px', padding: '15px', boxSizing: 'border-box', height: '100%', fontWeight: '400', borderTop: '1px solid ' + window.colors.border}}>
                        <span>AutoVitus confirms agreement and next steps.</span>
                    </div>
                </motion.div>
                <motion.div onMouseEnter={() => setPurchaseStage(1)} style={{display: 'flex', flexDirection: 'column', marginRight: 'auto', marginTop: window.innerWidth < 1550 && '40px', marginLeft: window.innerWidth < 1550 ? (window.innerWidth <= 738 ? "0px":'20px'):'0px', border: '0px solid ' + window.colors.border, borderRadius: '10px', alignItems: 'center', width: '270px', boxShadow: '0px 0px 10px 1px rgba(0,0,0,0.05)'}}>
                    <div style={{display: 'flex', flexDirection: 'row', paddingTop: '10px', marginBottom: '30px'}}>
                        <div className='center' style={{width: '31px', height: '31px', backgroundColor: window.colors.primary, borderRadius: '100px'}}>
                            <span style={{color: window.colors.white, fontSize: '20px', fontWeight: '500'}}>2</span>
                        </div>
                        <span style={{fontWeight: '700', fontSize: '20px', marginTop: '5px', marginLeft: '15px'}}>Credit Check</span>
                    </div>
                    <img src={Process2} style={{width: '44px', margin: window.innerWidth <= 738 && 'auto'}}></img>
                    <div className='center' style={{backgroundColor: window.colors.white, color: window.colors.primary, textAlign: 'center', width: '100%', marginTop: '30px', borderBottomLeftRadius: '10px', borderBottomRightRadius: '10px', padding: '15px', boxSizing: 'border-box', height: '100%', fontWeight: '400', borderTop: '1px solid ' + window.colors.border}}>
                        <span>Dealership performs a credit check for financing.</span>
                    </div>
                </motion.div>
                <motion.div onMouseEnter={() => setPurchaseStage(1)} style={{display: 'flex', flexDirection: 'column', marginRight: 'auto', marginTop: window.innerWidth < 1550 && '40px', marginLeft: window.innerWidth < 1550 ? (window.innerWidth <= 738 ? "0px":'20px'):'0px', border: '0px solid ' + window.colors.border, borderRadius: '10px', alignItems: 'center', width: '270px', boxShadow: '0px 0px 10px 1px rgba(0,0,0,0.05)'}}>
                    <div style={{display: 'flex', flexDirection: 'row', paddingTop: '10px', marginBottom: '30px'}}>
                        <div className='center' style={{width: '31px', height: '31px', backgroundColor: window.colors.primary, borderRadius: '100px'}}>
                            <span style={{color: window.colors.white, fontSize: '20px', fontWeight: '500'}}>3</span>
                        </div>
                        <span style={{fontWeight: '700', fontSize: '20px', marginTop: '5px', marginLeft: '15px'}}>Paperwork</span>
                    </div>
                    <img src={Process3} style={{width: '44px', margin: window.innerWidth <= 738 && 'auto'}}></img>
                    <div className='center' style={{backgroundColor: window.colors.white, color: window.colors.primary, textAlign: 'center', width: '100%', marginTop: '30px', borderBottomLeftRadius: '10px', borderBottomRightRadius: '10px', padding: '15px', boxSizing: 'border-box', height: '100%', fontWeight: '400', borderTop: '1px solid ' + window.colors.border}}>
                        <span>Dealership provides documents for remote purchase.</span>
                    </div>
                </motion.div>
                <motion.div onMouseEnter={() => setPurchaseStage(1)} style={{display: 'flex', flexDirection: 'column', marginRight: window.innerWidth < 1180 && 'auto', marginTop: window.innerWidth < 1550 && '40px', marginLeft: window.innerWidth < 1550 ? (window.innerWidth <= 738 ? "0px":'20px'):'0px', border: '0px solid ' + window.colors.border, borderRadius: '10px', alignItems: 'center', width: '270px', boxShadow: '0px 0px 10px 1px rgba(0,0,0,0.05)'}}>
                    <div style={{display: 'flex', flexDirection: 'row', paddingTop: '10px', marginBottom: '30px'}}>
                        <div className='center' style={{width: '31px', height: '31px', backgroundColor: window.colors.primary, borderRadius: '100px'}}>
                            <span style={{color: window.colors.white, fontSize: '20px', fontWeight: '500'}}>4</span>
                        </div>
                        <span style={{fontWeight: '700', fontSize: '20px', marginTop: '5px', marginLeft: '15px'}}>Delivery</span>
                    </div>
                    <img src={Process4} style={{width: '44px', margin: window.innerWidth <= 738 && 'auto'}}></img>
                    <div className='center' style={{backgroundColor: window.colors.white, color: window.colors.primary, textAlign: 'center', width: '100%', marginTop: '30px', borderBottomLeftRadius: '10px', borderBottomRightRadius: '10px', padding: '15px', boxSizing: 'border-box', height: '100%', fontWeight: '400', borderTop: '1px solid ' + window.colors.border}}>
                        <span>Vehicle shipped to complete your purchase.</span>
                    </div>
                </motion.div>
            </motion.div>
        </div>
    );
}

export default Main;
