import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from "framer-motion";

import hero from '../media/hero.png'
import heroMap from '../media/heroMap.png'
import suv from '../media/SUV.png'
import search from '../media/search.png'
import coupe from '../media/coupe.png'
import sedan from '../media/sedan.png'
import truck from '../media/truck.png'

import { Link, useNavigate } from 'react-router-dom'

const Main = (props) => {

    const [email, setEmail] = useState('')
    const [response, setResponse] = useState('')
    const [showResponse, setShowResponse] = useState(false)

    const subscribe = (email) => {
        var myHeaders = new Headers()
        myHeaders.append("Accept", "application/json")
        myHeaders.append("Content-Type", "application/json")

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            redirect: 'follow',
            body: JSON.stringify({
                email: email
            })
        }

        window.apiRequest('/contact/mailing_list', requestOptions, (result, status) => {   
            console.log(result)
            if (status === 200) {
                setResponse('Your email has been added successfully.')
                setEmail('')
            } else {
                setResponse('An error has occurred! Verify you have filled out all the required fields.')
            }
            setShowResponse(true) // Show response
            setTimeout(() => setShowResponse(false), 3000) // Hide after 3 seconds
        })
    }

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            subscribe(email)
        }
    }

    return (
        <div style={{display: 'flex', flexDirection: 'column', fontFamily: 'inter', color: window.colors.primary, ...props.style, textAlign: window.innerWidth < 1550 && 'center'}}>
            <h1 style={{fontSize: '64px', maxWidth: '930px', lineHeight: '110%', letterSpacing: '-5%', margin: 'auto', width: '90%', marginTop: '30px', textAlign: 'center'}}>Search Through Pre-Negotiated Deals for New Cars</h1>
            <p3 style={{fontSize: '20px', fontWeight: '400', color: window.colors.muted, width: '90%', marginLeft: 'auto', marginRight: 'auto', marginTop: '30px', textAlign: 'center', maxWidth: '745px'}}>Our platform is preparing for launch, but you can be among the first to know when we go live by signing up for our newsletter. Stay informed about our latest updates and exclusive deals as soon as they’re announced.</p3>
            <span style={{width: '90%', maxWidth: '745px', marginLeft: 'auto', marginRight: 'auto', marginTop: '50px', fontWeight: '500'}}>Newsletter Signup</span>
            <div style={{border: '1px solid ' + window.colors.border, display: 'flex', flexDirection: 'row', maxWidth: '745px', width: '90%', alignItems: 'center', height: '46px', borderRadius: '8px', marginLeft: 'auto', marginRight: 'auto', marginTop: '10px', zIndex: 100}}>
                <input placeholder='example@google.com' onKeyUp={handleKeyPress} value={email} onChange={(e) => setEmail(e.target.value)} style={{border: 'none', fontSize: '14px', flexGrow: 1, marginLeft: '20px', fontWeight: '500', fontFamily: 'inter',outline: 'none'}}></input>
                <motion.button onClick={() => subscribe(email)} whileHover={window.bAnim} style={{backgroundColor: window.colors.primary, marginLeft: 'auto', padding: '10px', marginRight: '5px', borderRadius: '5px', cursor: 'pointer', border: 'none', zIndex: 1}}>
                    <span style={{color: window.colors.white}}>Subscribe</span>
                </motion.button>
            </div>
            <AnimatePresence>
                {showResponse && (
                    <motion.span
                        initial={{ opacity: 0, y: -20 }}
                        animate={{ opacity: 1, y: 0 }}
                        exit={{ opacity: 0, y: -20 }}
                        transition={{ duration: 0.5 }}
                        style={{ marginTop: '0px', fontWeight: '500', fontSize: '16px', color: response.includes('error') ? 'red' : 'green'}}
                    >
                        {response}
                    </motion.span>
                )}
            </AnimatePresence>
            <img src={hero} style={{maxWidth: '853px', width: '90%', margin: 'auto'}}></img>
        </div>
    );
}

export default Main;
