import React, { Component, useState, useEffect, useRef, useLayoutEffect } from 'react'

import { Link, useNavigate } from "react-router-dom"

import Navbar from '../elements/Navbar'
import Footer from '../elements/Footer'
import PrivacyPolicyContent from '../elements/PrivacyPolicyContent'

const Home = (props) => {

    useEffect(() => {
        window.scrollTo(0,0);
    },[])

    useEffect(() => {
        document.title = 'AutoVitus - Privacy Policy'
    }, []);

    return (
        <div className='center' style={{width: '99.1vw', height: 'auto', backgroundColor: 'white', display: 'flex', flexDirection: 'column', overflowX: 'hidden'}}>
            <div style={{margin: 'auto', height: '100%', maxWidth: '1500px', paddingLeft: 'auto', paddingRight: 'auto'}}>
                <Navbar></Navbar>
                <PrivacyPolicyContent></PrivacyPolicyContent>
                <Footer style={{marginTop: '100px'}}></Footer>
            </div>
        </div>
    )
}

export default Home
