import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from "framer-motion";

import hero from '../media/hero.png'
import heroMap from '../media/heroMap.png'
import suv from '../media/SUV.png'
import search from '../media/search.png'
import coupe from '../media/coupe.png'
import sedan from '../media/sedan.png'
import truck from '../media/truck.png'

import { Link } from 'react-router-dom'

const Main = (props) => {

    const bodyStyles = ['SUV','Sedan','Truck','Coupe']
    const bodyImages = [suv, sedan, truck, coupe]

    const [searchTerm, setSearchTerm] = useState('')

    return (
        <div style={{fontFamily: 'inter', display: 'flex', flexDirection: 'column', color: window.colors.primary, ...props.style}}>
            <div style={{ fontFamily: 'Arial, sans-serif', padding: '20px', lineHeight: '1.6', color: '#333' }}>
                <h1 style={{ textAlign: 'center', fontSize: '28px', marginBottom: '10px' }}>PRIVACY POLICY</h1>
                <p style={{ textAlign: 'center', fontSize: '14px', marginBottom: '30px' }}>Last updated October 16, 2024</p>
                
                <p>
                    This Privacy Notice for <strong>Thinetics LLC</strong> ("we," "us," or "our"), describes how and why we might access, collect, 
                    store, use, and/or share ("process") your personal information when you use our services ("Services"), including when you:
                </p>

                <ul style={{ listStyle: 'disc', paddingLeft: '20px' }}>
                    <li>Visit our website at <a href="https://autovitus.com" style={{ color: '#1a73e8' }}>autovitus.com</a>, or any website of ours that links to this Privacy Notice</li>
                    <li>Engage with us in other related ways, including any sales, marketing, or events</li>
                </ul>

                <p>
                    <strong>Questions or concerns?</strong> Reading this Privacy Notice will help you understand your privacy rights and choices. 
                    We are responsible for making decisions about how your personal information is processed. If you do not agree with our policies and 
                    practices, please do not use our Services. If you still have any questions or concerns, please contact us at 
                    <a href="mailto:support@thinetics.io" style={{ color: '#1a73e8' }}>support@thinetics.io</a>.
                </p>

                <h2 style={{ marginTop: '20px', fontSize: '24px' }}>SUMMARY OF KEY POINTS</h2>
                <p>
                    This summary provides key points from our Privacy Notice, but you can find out more details about any of these topics by clicking 
                    the link following each key point or by using our table of contents below to find the section you are looking for.
                </p>

                <ul style={{ listStyle: 'circle', paddingLeft: '20px' }}>
                    <li>
                    <strong>What personal information do we process?</strong> When you visit, use, or navigate our Services, we may process personal 
                    information depending on how you interact with us and the Services, the choices you make, and the products and features you use. 
                    <a href="#" style={{ color: '#1a73e8' }}>Learn more</a> about personal information you disclose to us.
                    </li>
                    <li>
                    <strong>Do we process any sensitive personal information?</strong> Some of the information may be considered "special" or "sensitive" 
                    in certain jurisdictions, for example your racial or ethnic origins, sexual orientation, and religious beliefs. We may process 
                    sensitive personal information when necessary with your consent or as otherwise permitted by applicable law. 
                    <a href="#" style={{ color: '#1a73e8' }}>Learn more</a> about sensitive information we process.
                    </li>
                    <li>
                    <strong>Do we collect any information from third parties?</strong> We do not collect any information from third parties.
                    </li>
                    <li>
                    <strong>How do we process your information?</strong> We process your information to provide, improve, and administer our Services, 
                    communicate with you, for security and fraud prevention, and to comply with law. We may also process your information for other 
                    purposes with your consent. We process your information only when we have a valid legal reason to do so. 
                    <a href="#" style={{ color: '#1a73e8' }}>Learn more</a> about how we process your information.
                    </li>
                    <li>
                    <strong>In what situations and with which parties do we share personal information?</strong> We may share information in specific 
                    situations and with specific third parties. <a href="#" style={{ color: '#1a73e8' }}>Learn more</a> about when and with whom we 
                    share your personal information.
                    </li>
                    <li>
                    <strong>How do we keep your information safe?</strong> We have adequate organizational and technical processes and procedures 
                    in place to protect your personal information. However, no electronic transmission over the internet or information storage 
                    technology can be guaranteed to be 100% secure, so we cannot promise or guarantee that hackers, cybercriminals, or other 
                    unauthorized third parties will not be able to defeat our security and improperly collect, access, steal, or modify your information. 
                    <a href="#" style={{ color: '#1a73e8' }}>Learn more</a> about how we keep your information safe.
                    </li>
                    <li>
                    <strong>What are your rights?</strong> Depending on where you are located geographically, the applicable privacy law may mean 
                    you have certain rights regarding your personal information. <a href="#" style={{ color: '#1a73e8' }}>Learn more</a> about your privacy rights.
                    </li>
                    <li>
                    <strong>How do you exercise your rights?</strong> The easiest way to exercise your rights is by submitting a data subject access request, 
                    or by contacting us. We will consider and act upon any request in accordance with applicable data protection laws.
                    </li>
                    <li>
                    <strong>Want to learn more about what we do with any information we collect?</strong> <a href="#" style={{ color: '#1a73e8' }}>Review the Privacy Notice in full</a>.
                    </li>
                </ul>
            </div>
            <div style={{ fontFamily: 'Arial, sans-serif', padding: '20px', lineHeight: '1.6', color: '#333' }}>
                <h2 style={{ fontSize: '24px', marginBottom: '10px' }}>TABLE OF CONTENTS</h2>
                <ol style={{ paddingLeft: '20px', marginBottom: '20px' }}>
                <li>WHAT INFORMATION DO WE COLLECT?</li>
                <li>HOW DO WE PROCESS YOUR INFORMATION?</li>
                <li>WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?</li>
                <li>DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?</li>
                <li>HOW LONG DO WE KEEP YOUR INFORMATION?</li>
                <li>HOW DO WE KEEP YOUR INFORMATION SAFE?</li>
                <li>DO WE COLLECT INFORMATION FROM MINORS?</li>
                <li>WHAT ARE YOUR PRIVACY RIGHTS?</li>
                <li>CONTROLS FOR DO-NOT-TRACK FEATURES</li>
                <li>DO UNITED STATES RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?</li>
                <li>DO WE MAKE UPDATES TO THIS NOTICE?</li>
                <li>HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</li>
                <li>HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?</li>
                </ol>

                <h2 style={{ fontSize: '24px', marginBottom: '10px' }}>1. WHAT INFORMATION DO WE COLLECT?</h2>

                <h3 style={{ fontSize: '20px', marginBottom: '5px' }}>Personal information you disclose to us</h3>
                <p><strong>In Short:</strong> We collect personal information that you provide to us.</p>
                <p>
                We collect personal information that you voluntarily provide to us when you express an interest in obtaining information 
                about us or our products and Services, when you participate in activities on the Services, or otherwise when you contact us.
                </p>
                <p><strong>Personal Information Provided by You:</strong> The personal information that we collect depends on the context 
                of your interactions with us and the Services, the choices you make, and the products and features you use. The personal 
                information we collect may include the following:
                </p>
                <ul style={{ listStyle: 'disc', paddingLeft: '20px' }}>
                <li>Names</li>
                <li>Phone numbers</li>
                <li>Email addresses</li>
                </ul>

                <p>
                <strong>Sensitive Information:</strong> When necessary, with your consent or as otherwise permitted by applicable law, 
                we process the following categories of sensitive information:
                </p>
                <ul style={{ listStyle: 'disc', paddingLeft: '20px' }}>
                <li>Credit worthiness data</li>
                </ul>
                <p>
                All personal information that you provide to us must be true, complete, and accurate, and you must notify us of any changes 
                to such personal information.
                </p>

                <h3 style={{ fontSize: '20px', marginBottom: '5px' }}>Information automatically collected</h3>
                <p><strong>In Short:</strong> Some information — such as your Internet Protocol (IP) address and/or browser and device characteristics — 
                is collected automatically when you visit our Services.
                </p>
                <p>
                We automatically collect certain information when you visit, use, or navigate the Services. This information does not reveal 
                your specific identity (like your name or contact information) but may include device and usage information, such as your IP address, 
                browser and device characteristics, operating system, language preferences, referring URLs, device name, country, location, 
                information about how and when you use our Services, and other technical information. This information is primarily needed to 
                maintain the security and operation of our Services, and for our internal analytics and reporting purposes.
                </p>

                <h3 style={{ fontSize: '20px', marginBottom: '5px' }}>The information we collect includes:</h3>
                <ul style={{ listStyle: 'disc', paddingLeft: '20px' }}>
                <li>
                    <strong>Log and Usage Data:</strong> Log and usage data is service-related, diagnostic, usage, and performance information 
                    our servers automatically collect when you access or use our Services and which we record in log files. Depending on how you 
                    interact with us, this log data may include your IP address, device information, browser type, and settings and information 
                    about your activity in the Services (such as the date/time stamps associated with your usage, pages and files viewed, searches, 
                    and other actions you take such as which features you use), device event information (such as system activity, error reports 
                    (sometimes called "crash dumps"), and hardware settings).
                </li>
                <li>
                    <strong>Device Data:</strong> We collect device data such as information about your computer, phone, tablet, or other device 
                    you use to access the Services. Depending on the device used, this device data may include information such as your IP address 
                    (or proxy server), device and application identification numbers, location, browser type, hardware model, Internet service 
                    provider and/or mobile carrier, operating system, and system configuration information.
                </li>
                <li>
                    <strong>Location Data:</strong> We collect location data such as information about your device's location, which can be either 
                    precise or imprecise. How much information we collect depends on the type and settings of the device you use to access the Services. 
                    For example, we may use GPS and other technologies to collect geolocation data that tells us your current location (based on your 
                    IP address). You can opt out of allowing us to collect this information either by refusing access to the information or by disabling 
                    your Location setting on your device. However, if you choose to opt out, you may not be able to use certain aspects of the Services.
                </li>
                </ul>
            </div>
            <div style={{ fontFamily: 'Arial, sans-serif', padding: '20px', lineHeight: '1.6', color: '#333' }}>
                <h2 style={{ fontSize: '24px', marginBottom: '10px' }}>2. HOW DO WE PROCESS YOUR INFORMATION?</h2>
                <p><strong>In Short:</strong> We process your information to provide, improve, and administer our Services, communicate with you, 
                for security and fraud prevention, and to comply with law. We may also process your information for other purposes with your consent.
                </p>
                <p>We process your personal information for a variety of reasons, depending on how you interact with our Services, including:</p>
                <ul style={{ listStyle: 'disc', paddingLeft: '20px' }}>
                <li><strong>To deliver and facilitate delivery of services to the user.</strong> We may process your information to provide you with the requested service.</li>
                <li><strong>To respond to user inquiries/offer support to users.</strong> We may process your information to respond to your inquiries and solve any potential issues you might have with the requested service.</li>
                <li><strong>To send administrative information to you.</strong> We may process your information to send you details about our products and services, changes to our terms and policies, and other similar information.</li>
                <li><strong>To fulfill and manage your orders.</strong> We may process your information to fulfill and manage your orders, payments, returns, and exchanges made through the Services.</li>
                <li><strong>To request feedback.</strong> We may process your information when necessary to request feedback and to contact you about your use of our Services.</li>
                <li><strong>To send you marketing and promotional communications.</strong> We may process the personal information you send to us for our marketing purposes, if this is in accordance with your marketing preferences. You can opt out of our marketing emails at any time.</li>
                <li><strong>To identify usage trends.</strong> We may process information about how you use our Services to better understand how they are being used so we can improve them.</li>
                </ul>

                <h2 style={{ fontSize: '24px', marginBottom: '10px' }}>3. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?</h2>
                <p><strong>In Short:</strong> We may share information in specific situations described in this section and/or with the following third parties.</p>
                <ul style={{ listStyle: 'disc', paddingLeft: '20px' }}>
                <li><strong>Business Transfers.</strong> We may share or transfer your information in connection with, or during negotiations of, any merger, sale of company assets, financing, or acquisition of all or a portion of our business to another company.</li>
                <li><strong>Affiliates.</strong> We may share your information with our affiliates, in which case we will require those affiliates to honor this Privacy Notice.</li>
                <li><strong>Business Partners.</strong> We may share your information with our business partners to offer you certain products, services, or promotions.</li>
                </ul>

                <h2 style={{ fontSize: '24px', marginBottom: '10px' }}>4. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?</h2>
                <p><strong>In Short:</strong> We may use cookies and other tracking technologies to collect and store your information.</p>
                <p>
                We may use cookies and similar tracking technologies (like web beacons and pixels) to gather information when you interact with our Services. 
                Some online tracking technologies help us maintain the security of our Services, prevent crashes, fix bugs, save your preferences, 
                and assist with basic site functions.
                </p>
                <p>
                We also permit third parties and service providers to use online tracking technologies on our Services for analytics and advertising, 
                including to help manage and display advertisements, to tailor advertisements to your interests, or to send abandoned shopping cart reminders 
                (depending on your communication preferences).
                </p>
                <p>
                To the extent these online tracking technologies are deemed to be a "sale"/"sharing" under applicable US state laws, you can opt out by submitting 
                a request as described under "DO UNITED STATES RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?" below.
                </p>
                <p>
                <strong>Google Analytics:</strong> We may share your information with Google Analytics to track and analyze the use of the Services. 
                To opt out, visit <a href="https://tools.google.com/dlpage/gaoptout" style={{ color: '#1a73e8' }}>Google Analytics opt-out page</a> 
                or use <a href="http://optout.networkadvertising.org/" style={{ color: '#1a73e8' }}>other opt-out methods</a>. For more information, 
                visit the <a href="https://policies.google.com/privacy" style={{ color: '#1a73e8' }}>Google Privacy & Terms</a> page.
                </p>

                <h2 style={{ fontSize: '24px', marginBottom: '10px' }}>5. HOW LONG DO WE KEEP YOUR INFORMATION?</h2>
                <p><strong>In Short:</strong> We keep your information for as long as necessary to fulfill the purposes outlined in this Privacy Notice unless otherwise required by law.</p>
                <p>
                We will only keep your personal information for as long as it is necessary for the purposes set out in this Privacy Notice, 
                unless a longer retention period is required or permitted by law (such as tax, accounting, or other legal requirements).
                </p>
                <p>
                When we have no ongoing legitimate business need to process your personal information, we will either delete or anonymize it. 
                If this is not possible (for example, if it is stored in backup archives), we will securely store your personal information 
                and isolate it from further processing until deletion is possible.
                </p>
            </div>
            <div style={{ fontFamily: 'Arial, sans-serif', padding: '20px', lineHeight: '1.6', color: '#333' }}>
                <h2 style={{ fontSize: '24px', marginBottom: '10px' }}>6. HOW DO WE KEEP YOUR INFORMATION SAFE?</h2>
                <p><strong>In Short:</strong> We aim to protect your personal information through a system of organizational and technical security measures.</p>
                <p>
                We have implemented appropriate and reasonable technical and organizational security measures designed to protect the security 
                of any personal information we process. However, despite our safeguards and efforts, no electronic transmission over the internet 
                or information storage technology can be guaranteed to be 100% secure. We cannot guarantee that hackers, cybercriminals, or 
                unauthorized third parties will not defeat our security and improperly collect, access, steal, or modify your information.
                </p>
                <p>
                Although we will do our best to protect your personal information, transmission of personal information to and from our Services 
                is at your own risk. You should only access the Services within a secure environment.
                </p>

                <h2 style={{ fontSize: '24px', marginBottom: '10px' }}>7. DO WE COLLECT INFORMATION FROM MINORS?</h2>
                <p><strong>In Short:</strong> We do not knowingly collect data from or market to children under 18 years of age.</p>
                <p>
                We do not knowingly collect, solicit data from, or market to children under 18, nor do we knowingly sell such personal information. 
                By using the Services, you represent that you are at least 18 or that you are the parent or guardian of a minor and consent to their use.
                </p>
                <p>
                If we learn that personal information from users under 18 years of age has been collected, we will deactivate the account and 
                promptly delete such data. If you become aware of any data we have collected from children under 18, please contact us at 
                <a href="mailto:support@autovitus.com" style={{ color: '#1a73e8' }}>support@autovitus.com</a>.
                </p>

                <h2 style={{ fontSize: '24px', marginBottom: '10px' }}>8. WHAT ARE YOUR PRIVACY RIGHTS?</h2>
                <p><strong>In Short:</strong> You may review, change, or terminate your account at any time, depending on your location.</p>
                <p>
                <strong>Withdrawing your consent:</strong> If we rely on your consent to process personal information, you have the right to 
                withdraw it at any time by contacting us using the details provided in "HOW CAN YOU CONTACT US ABOUT THIS NOTICE?" below.
                </p>
                <p>
                Please note that withdrawing consent will not affect the lawfulness of prior processing. It may also not affect processing conducted 
                on other legal grounds beyond consent.
                </p>
                <p>
                <strong>Opting out of marketing communications:</strong> You can unsubscribe from marketing emails at any time by clicking the 
                unsubscribe link or contacting us. You may still receive service-related messages necessary for your account.
                </p>
                <p>
                If you have any questions about your privacy rights, contact us at <a href="mailto:support@thinetics.io" style={{ color: '#1a73e8' }}>support@thinetics.io</a>.
                </p>

                <h2 style={{ fontSize: '24px', marginBottom: '10px' }}>9. CONTROLS FOR DO-NOT-TRACK FEATURES</h2>
                <p>
                Most browsers and some mobile apps include a Do-Not-Track ("DNT") feature to signal your preference not to be tracked online. 
                However, no standard for DNT signals has been finalized, and we do not currently respond to them.
                </p>
                <p>
                If an industry standard for DNT signals is adopted, we will inform you of such practices in a revised version of this Privacy Notice.
                </p>
                <p>
                California law requires us to disclose how we respond to DNT signals. As there is no industry or legal standard, we do not respond 
                to these signals at this time.
                </p>

                <h2 style={{ fontSize: '24px', marginBottom: '10px' }}>10. DO UNITED STATES RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?</h2>
                <p><strong>In Short:</strong> Residents of specific US states may have additional privacy rights.</p>
                <p>
                If you reside in California, Colorado, Connecticut, Delaware, Florida, Indiana, Iowa, Kentucky, Montana, New Hampshire, New Jersey, 
                Oregon, Tennessee, Texas, Utah, or Virginia, you may have the right to access, correct, receive, or delete your personal information. 
                You may also withdraw your consent for processing.
                </p>
                <h3 style={{ fontSize: '20px', marginBottom: '5px' }}>Categories of Personal Information We Collect</h3>
                <p>We have collected the following categories of personal information in the past twelve (12) months:</p>
            </div>
            <div style={{ fontFamily: 'Arial, sans-serif', padding: '20px', lineHeight: '1.6', color: '#333' }}>
                <h3 style={{ fontSize: '20px', marginBottom: '10px' }}>A. Identifiers</h3>
                <p>
                Contact details, such as real name, alias, postal address, telephone or mobile contact number, unique personal identifier, 
                online identifier, Internet Protocol address, email address, and account name — <strong>NO</strong>
                </p>

                <h3 style={{ fontSize: '20px', marginBottom: '10px' }}>B. Personal information as defined in the California Customer Records statute</h3>
                <p>
                Name, contact information, education, employment, employment history, and financial information — <strong>NO</strong>
                </p>

                <h3 style={{ fontSize: '20px', marginBottom: '10px' }}>C. Protected classification characteristics under state or federal law</h3>
                <p>
                Gender, age, date of birth, race and ethnicity, national origin, marital status, and other demographic data — <strong>NO</strong>
                </p>

                <h3 style={{ fontSize: '20px', marginBottom: '10px' }}>D. Commercial information</h3>
                <p>Transaction information, purchase history, financial details, and payment information — <strong>NO</strong></p>

                <h3 style={{ fontSize: '20px', marginBottom: '10px' }}>E. Biometric information</h3>
                <p>Fingerprints and voiceprints — <strong>NO</strong></p>

                <h3 style={{ fontSize: '20px', marginBottom: '10px' }}>F. Internet or other similar network activity</h3>
                <p>
                Browsing history, search history, online behavior, interest data, and interactions with our and other websites, 
                applications, systems, and advertisements — <strong>NO</strong>
                </p>

                <h3 style={{ fontSize: '20px', marginBottom: '10px' }}>G. Geolocation data</h3>
                <p>Device location — <strong>NO</strong></p>

                <h3 style={{ fontSize: '20px', marginBottom: '10px' }}>H. Audio, electronic, sensory, or similar information</h3>
                <p>
                Images and audio, video or call recordings created in connection with our business activities — <strong>NO</strong>
                </p>

                <h3 style={{ fontSize: '20px', marginBottom: '10px' }}>I. Professional or employment-related information</h3>
                <p>
                Business contact details to provide our Services at a business level, job title, work history, and professional qualifications 
                — <strong>NO</strong>
                </p>

                <h3 style={{ fontSize: '20px', marginBottom: '10px' }}>J. Education Information</h3>
                <p>Student records and directory information — <strong>NO</strong></p>

                <h3 style={{ fontSize: '20px', marginBottom: '10px' }}>K. Inferences drawn from collected personal information</h3>
                <p>Inferences to create a profile about preferences and characteristics — <strong>YES</strong></p>

                <h3 style={{ fontSize: '20px', marginBottom: '10px' }}>L. Sensitive Personal Information</h3>
                <p><strong>NO</strong></p>

                <h2 style={{ fontSize: '24px', marginTop: '20px', marginBottom: '10px' }}>Sources of Personal Information</h2>
                <p>
                Learn more about the sources of personal information we collect in <strong>"WHAT INFORMATION DO WE COLLECT?"</strong>
                </p>

                <h2 style={{ fontSize: '24px', marginTop: '20px', marginBottom: '10px' }}>How We Use and Share Personal Information</h2>
                <p>
                Learn about how we use your personal information in <strong>"HOW DO WE PROCESS YOUR INFORMATION?"</strong>
                </p>

                <p>
                <strong>Will your information be shared with anyone else?</strong> We may disclose your personal information with service providers 
                under written contracts. Learn more in <strong>"WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?"</strong>
                </p>

                <h2 style={{ fontSize: '24px', marginTop: '20px', marginBottom: '10px' }}>Your Rights</h2>
                <p>You have the following rights under certain US state data protection laws:</p>
                <ul style={{ listStyle: 'disc', paddingLeft: '20px' }}>
                <li>Right to know whether or not we are processing your personal data</li>
                <li>Right to access your personal data</li>
                <li>Right to correct inaccuracies in your personal data</li>
                <li>Right to request deletion of your personal data</li>
                <li>Right to obtain a copy of the personal data you shared with us</li>
                <li>Right to non-discrimination for exercising your rights</li>
                <li>Right to opt out of targeted advertising or profiling</li>
                </ul>
            </div>
            <div style={{ fontFamily: 'Arial, sans-serif', padding: '20px', lineHeight: '1.6', color: '#333' }}>
                <h3 style={{ fontSize: '20px', marginBottom: '10px' }}>Additional State-Specific Rights</h3>
                <ul style={{ listStyle: 'disc', paddingLeft: '20px' }}>
                <li>
                    Right to obtain a list of the categories of third parties to which we have disclosed personal data (as permitted by applicable law, including California's and Delaware's privacy law)
                </li>
                <li>
                    Right to obtain a list of specific third parties to which we have disclosed personal data (as permitted by applicable law, including Oregon’s privacy law)
                </li>
                <li>
                    Right to limit the use and disclosure of sensitive personal data (as permitted by applicable law, including California’s privacy law)
                </li>
                <li>
                    Right to opt out of the collection of sensitive data collected through voice or facial recognition features (as permitted by applicable law, including Florida’s privacy law)
                </li>
                </ul>

                <h2 style={{ fontSize: '24px', marginTop: '20px', marginBottom: '10px' }}>How to Exercise Your Rights</h2>
                <p>
                To exercise your rights, you can submit a data subject access request, email us at 
                <a href="mailto:support@autovitus.com" style={{ color: '#1a73e8' }}> support@autovitus.com</a>, or use the contact details provided at the bottom of this document.
                </p>
                <p>
                You can also designate an authorized agent to make a request on your behalf. We may request proof that the agent is authorized to act on your behalf.
                </p>

                <h2 style={{ fontSize: '24px', marginTop: '20px', marginBottom: '10px' }}>Request Verification</h2>
                <p>
                Upon receiving your request, we will verify your identity to ensure you are the person about whom we have collected information. If we cannot verify your identity, 
                we may ask for additional information to complete the verification process.
                </p>
                <p>
                If an authorized agent makes a request, they must provide written permission from you along with additional identity verification information.
                </p>

                <h2 style={{ fontSize: '24px', marginTop: '20px', marginBottom: '10px' }}>Appeals</h2>
                <p>
                If we decline to act on your request, you may appeal our decision by emailing us at 
                <a href="mailto:support@thinetics.io" style={{ color: '#1a73e8' }}> support@thinetics.io</a>. We will provide a written response, including a reason for the decision. 
                If denied, you may submit a complaint to your state attorney general.
                </p>

                <h2 style={{ fontSize: '24px', marginTop: '20px', marginBottom: '10px' }}>California "Shine The Light" Law</h2>
                <p>
                California residents may request information once a year about the personal data we disclosed to third parties for direct marketing purposes. 
                Submit your request using the contact details in the section <strong>"HOW CAN YOU CONTACT US ABOUT THIS NOTICE?"</strong>
                </p>

                <h2 style={{ fontSize: '24px', marginTop: '20px', marginBottom: '10px' }}>11. DO WE MAKE UPDATES TO THIS NOTICE?</h2>
                <p><strong>In Short:</strong> Yes, we will update this notice as needed to stay compliant with relevant laws.</p>
                <p>
                We may update this Privacy Notice from time to time. The updated version will have a revised date at the top. If we make material changes, 
                we will notify you either by posting a notice or by sending a direct notification. Please review this notice regularly to stay informed.
                </p>

                <h2 style={{ fontSize: '24px', marginTop: '20px', marginBottom: '10px' }}>12. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</h2>
                <p>If you have any questions or comments, you can contact us by email at:</p>
                <p><a href="mailto:support@autovitus.com" style={{ color: '#1a73e8' }}>support@autovitus.com</a></p>
                <p>Or by post at:</p>
                <p>
                Thinetics LLC<br />
                Online<br />
                __________<br />
                United States
                </p>

                <h2 style={{ fontSize: '24px', marginTop: '20px', marginBottom: '10px' }}>13. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?</h2>
                <p>
                Depending on the laws in your country or state of residence, you may have the right to request access to, correct inaccuracies, 
                or delete your personal data. You may also withdraw your consent for our processing of your personal information.
                </p>
                <p>
                To make such a request, please submit a data subject access request or contact us using the details provided above.
                </p>
            </div>
        </div>
    );
}

export default Main;
