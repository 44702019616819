import React, { Component, useState, useEffect, useRef, useLayoutEffect } from 'react'

import { Link, useNavigate } from "react-router-dom"

import Navbar from '../elements/Navbar'
import FeaturedCars from '../elements/FeaturedCars'
import EmailList from '../elements/EmailList'
import Footer from '../elements/Footer'
import TheProcessDetailed from '../elements/TheProcessDetailed'

const Home = (props) => {

    useEffect(() => {
        window.scrollTo(0,0);
    },[])

    useEffect(() => {
        document.title = 'AutoVitus - The Process'
    }, []);

    return (
        <div className='center' style={{width: '99.1vw', height: 'auto', backgroundColor: 'white', display: 'flex', flexDirection: 'column', overflowX: 'hidden'}}>
            <div style={{margin: 'auto', height: '100%', maxWidth: '1500px', width: '100%'}}>
                <Navbar></Navbar>
                <TheProcessDetailed></TheProcessDetailed>
                <FeaturedCars style={{marginTop: '100px'}}></FeaturedCars>
                <EmailList style={{marginTop: '150px'}}></EmailList>
                <Footer style={{marginTop: '100px'}}></Footer>
            </div>
        </div>
    )
}

export default Home
