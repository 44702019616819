import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from "framer-motion";
import { Link } from "react-router-dom";

import FeatureIcon from '../media/FeatureIcon.png'

const Main = (props) => {

    const fields = [
        {
            title: 'Name',
            key: 'name'
        },
        {
            title: 'Email',
            key: 'email'
        }
    ]

    const [formData, setFormData] = useState({
        name: '',
        email: '',
        feedback:'',
    })

    const handleDataChange = (key, value) => {
        setFormData(() => ({
            ...formData,
            [key]: value,
        }))
    }

    const [response, setResponse] = useState('')
    const [showResponse, setShowResponse] = useState(false)

    const submit = (formData) => {
        var myHeaders = new Headers()
        myHeaders.append("Accept", "application/json")
        myHeaders.append("Content-Type", "application/json")

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            redirect: 'follow',
            body: JSON.stringify(formData)
        }

        window.apiRequest('/contact', requestOptions, (result, status) => {   
            console.log(result)
            if (status === 200) {
                setResponse('Your message has been delivered. We will be in contact shortly.')
                setFormData({
                    name: '',
                    email: '',
                    feedback:'',
                })
            } else {
                setResponse('An error has occurred! Verify you have filled out all the required fields.')
            }
            setShowResponse(true) // Show response
            setTimeout(() => setShowResponse(false), 3000) // Hide after 3 seconds
        })
    }

    const handleKeyPress = (e, formData) => {
        if (e.key === 'Enter') {
            submit(formData)
        }
    }

    return (
        <div style={{display: 'flex', flexDirection: 'column', height: 'auto', fontFamily: 'inter', color: window.colors.primary, ...props.style, textAlign: 'center', width: '100%'}}>
            <span style={{color: window.colors.primary, fontSize: window.innerWidth < 600 ? '48px':'96px', fontWeight: '700'}}>Feedback</span>
            <span style={{color: window.colors.muted, fontSize: '20px', fontWeight: '400', maxWidth: '1000px', width: '90%', margin: 'auto', marginTop: '30px',}}>Help us improve! If there’s anything about our website or product that doesn’t meet your expectations, let us know. Your feedback is invaluable in making sure we deliver the experience you envision.</span>
            <div style={{backgroundColor: window.colors.primary, display: 'flex', flexDirection: 'column', padding: '30px', maxWidth: '800px', width: '70%', margin: 'auto', borderRadius: '15px', marginTop: '50px'}}>
                <div style={{borderBottom: '1px solid ' + window.colors.border, textAlign: 'left', paddingBottom: '10px'}}>
                    <span style={{color: window.colors.white, fontSize: '24px', fontWeight: '700'}}>Feedback</span>
                </div>
                <div style={{display: 'grid', gridTemplateColumns: window.innerWidth < 900 ? 'repeat(1, 1fr)':'repeat(2, 1fr)', gap: '50px', marginTop: '50px', textAlign: 'left'}}>
                    {fields.map((item, index) => {
                        return (
                            <div style={{display: 'flex', flexDirection: 'column'}}>
                                <span style={{fontWeight: '700', color: window.colors.white}}>{item.title}</span>
                                <input onChange={(e) => handleDataChange(item.key, e.target.value)} value={formData[item.key]} style={{marginTop: '10px', height: '40px', paddingLeft: '15px', fontSize: '16px', fontFamily: 'inter', fontWeight: '500', borderRadius: '5px', border: '0px solid ' + window.colors.white, backgroundColor: window.colors.white, color: window.colors.primary}}></input>
                            </div>
                        )
                    })}
                </div>
                <span style={{fontWeight: '700', color: window.colors.white, textAlign: 'left', marginTop: '40px'}}>Message*</span>
                <textarea onChange={(e) => handleDataChange('feedback', e.target.value)} value={formData.feedback} onKeyUp={(e) => handleKeyPress(e, formData)} style={{marginTop: '10px', height: '120px', paddingLeft: '15px', fontSize: '16px', fontFamily: 'inter', fontWeight: '500', borderRadius: '5px', border: '0px solid ' + window.colors.white, backgroundColor: window.colors.white, color: window.colors.primary}}></textarea>
                <AnimatePresence>
                    {showResponse && (
                        <motion.span
                            initial={{ opacity: 0, y: 0 }}
                            animate={{ opacity: 1, y: 20 }}
                            exit={{ opacity: 0, y: 0 }}
                            transition={{ duration: 0.5 }}
                            style={{ marginTop: '0px', fontWeight: '500', fontSize: '16px', color: response.includes('error') ? 'red' : 'green'}}
                        >
                            {response}
                        </motion.span>
                    )}
                </AnimatePresence>
                <motion.button onClick={() => submit(formData)} whileHover={{width: '350px'}} style={{width: window.innerWidth < 450 ? '90%':'300px', margin: 'auto', marginTop: '50px', backgroundColor: window.colors.white, fontFamily: 'inter', border: 'none', fontWeight: '600', fontSize: '16px', padding: '10px 5px 10px 5px', borderRadius: '5px', cursor: 'pointer'}}>Send</motion.button>
            </div>
           
        </div>
    );
}

export default Main;
