import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from "framer-motion";
import { Link } from "react-router-dom";
import Modal from 'react-modal'
import Select from 'react-select';

import downArrow from '../media/down-arrow.png'

const Main = (props) => {

    const [taxMessage, setTaxMessage] = useState(false)
    const [noticePopup, setNoticePopup] = useState(0)

    console.log(props)

    const stateOptions = [
        { value: 'AL', label: 'AL' }, { value: 'AK', label: 'AK' }, { value: 'AZ', label: 'AZ' }, { value: 'AR', label: 'AR' }, { value: 'CA', label: 'CA' },
        { value: 'CO', label: 'CO' }, { value: 'CT', label: 'CT' }, { value: 'DE', label: 'DE' }, { value: 'FL', label: 'FL' }, { value: 'GA', label: 'GA' },
        { value: 'HI', label: 'HI' }, { value: 'ID', label: 'ID' }, { value: 'IL', label: 'IL' }, { value: 'IN', label: 'IN' }, { value: 'IA', label: 'IA' },
        { value: 'KS', label: 'KS' }, { value: 'KY', label: 'KY' }, { value: 'LA', label: 'LA' }, { value: 'ME', label: 'ME' }, { value: 'MD', label: 'MD' },
        { value: 'MA', label: 'MA' }, { value: 'MI', label: 'MI' }, { value: 'MN', label: 'MN' }, { value: 'MS', label: 'MS' }, { value: 'MO', label: 'MO' },
        { value: 'MT', label: 'MT' }, { value: 'NE', label: 'NE' }, { value: 'NV', label: 'NV' }, { value: 'NH', label: 'NH' }, { value: 'NJ', label: 'NJ' },
        { value: 'NM', label: 'NM' }, { value: 'NY', label: 'NY' }, { value: 'NC', label: 'NC' }, { value: 'ND', label: 'ND' }, { value: 'OH', label: 'OH' },
        { value: 'OK', label: 'OK' }, { value: 'OR', label: 'OR' }, { value: 'PA', label: 'PA' }, { value: 'RI', label: 'RI' }, { value: 'SC', label: 'SC' },
        { value: 'SD', label: 'SD' }, { value: 'TN', label: 'TN' }, { value: 'TX', label: 'TX' }, { value: 'UT', label: 'UT' }, { value: 'VT', label: 'VT' },
        { value: 'VA', label: 'VA' }, { value: 'WA', label: 'WA' }, { value: 'WV', label: 'WV' }, { value: 'WI', label: 'WI' }, { value: 'WY', label: 'WY' }
    ];

    const creditOptions = [{value: 1, label: 'Tier 1 (781-850)'},{value: 2, label: 'Tier 2 (661-780)'},{value: 3, label: 'Tier 3 (601-660)'},{value: 4, label: 'Tier 4 (501-600)'},{value: 1, label: 'Tier 5 (300-500)'},]

    const [selectedState, setSelectedState] = useState('')
    const [selectedCredit, setSelectedCredit] = useState(-1)

    const customStyles = {
        menu: (provided) => ({
          ...provided,
          maxHeight: '200px', // Set max height here
          overflowY: 'auto',  // Add scroll if the content exceeds max height
        }),
      };

    useEffect(() => {
        if (localStorage.getItem('tax') !== undefined && localStorage.getItem('includeTaxFees') === 'true')
        {
            calculateLeaseValues(props.car, true, (localStorage.getItem('tax') / 100))
            calculateFinanceValues(props.car, true, (localStorage.getItem('tax') / 100))
            props.setIncludeTaxFees(true)
        }
        else
        {
            calculateLeaseValues(props.car)
            calculateFinanceValues(props.car)
        }
    },[props.car])

    const getRates = (state, addTaxFees) => {
        var myHeaders = new Headers();
        myHeaders.append("Accept", "application/json");
        myHeaders.append("Content-Type", "application/json");
    
        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow',
        }

        window.apiRequest('/state/tax/' + state.value, requestOptions, (result, status) => {   
            console.log(result)
            if (status === 200)
            {
                localStorage.setItem('state', state.value) 
                localStorage.setItem('tax', result)
                localStorage.setItem('includeTaxFees', addTaxFees)
                calculateLeaseValues(props.car, addTaxFees, (result / 100))
                calculateFinanceValues(props.car, addTaxFees, (result / 100))
            } else {}
        })
    }

    const calculateLeaseValues = (car, taxFees, tax) => {
        const dpAmount = 0.05
        const depreciation = car.discounted_msrp - car.residual
        const leaseCharge = (car.msrp + car.residual)*car.money_factor;
        let fees = props?.car?.fees?.reduce((total, item) => total + item.cost, 0)
        if (taxFees)
        {
            const monthlyPayment = ((depreciation - (car.discounted_msrp * dpAmount)) * (1 + tax) + fees) / car.lease_term_length + leaseCharge;
            props.setLeaseMonthly(monthlyPayment.toFixed(2));
            props.setLeaseDown((car.discounted_msrp * dpAmount).toFixed(2));
        }
        else
        {
            props.setLeaseMonthly((((car.discounted_msrp - car.residual) - (car.discounted_msrp * dpAmount))/car.lease_term_length).toFixed(2))
            props.setLeaseDown((car.discounted_msrp * dpAmount).toFixed(2))
        }
    }

    const calculateFinanceInterest = (car, dpAmount) => {
        const principal = car.discounted_msrp - (car.discounted_msrp * dpAmount);
        const annualInterestRate = car.interest_rate
        const monthlyRate = annualInterestRate / 12;
        const termLength = car.finance_term_length;
    
        // Calculate the monthly payment using the amortized loan formula
        const monthlyPayment = (principal * monthlyRate * Math.pow(1 + monthlyRate, termLength)) /
                               (Math.pow(1 + monthlyRate, termLength) - 1);
    
        // Calculate the total payments over the term
        const totalPayments = monthlyPayment * termLength;
    
        // Calculate the total interest by subtracting the principal from the total payments
        const totalInterest = totalPayments - principal;
        console.log(totalInterest)
        return totalInterest;
    }

    const calculateFinanceValues = (car, taxFees, tax) => {
        const dpAmount = 0.1;
        let fees = car?.fees?.reduce((total, item) => total + item.cost, 0) || 0;
        let interestCharge = calculateFinanceInterest(car, dpAmount);
    
        let monthlyPayment, downPayment;
    
        if (taxFees) {
            monthlyPayment = ((((car.discounted_msrp + interestCharge - (car.discounted_msrp * dpAmount)) * (1 + tax)) + fees) / car.finance_term_length).toFixed(2);
            downPayment = (car.discounted_msrp * dpAmount).toFixed(2);
        } else {
            monthlyPayment = (((car.discounted_msrp - (car.discounted_msrp * dpAmount)) / car.finance_term_length).toFixed(2));
            downPayment = (car.discounted_msrp * dpAmount).toFixed(2);
        }
    
        // Set the values to props
        props.setFinanceMonthly(monthlyPayment);
        props.setFinanceDown(downPayment);
    }
    

    const handleNoTaxFees = () => {
        if (props.includeTaxFees)
        {
            props.setIncludeTaxFees(false)
            calculateLeaseValues(props.car, false, 0)
            calculateFinanceValues(props.car, false, 0)
            localStorage.setItem('includeTaxFees', false)
        }
        else
        {
            if (localStorage.getItem('tax') !== undefined && localStorage.getItem('tax') !== null)
            {
                props.setIncludeTaxFees(true)
                calculateLeaseValues(props.car, true, (localStorage.getItem('tax') / 100))
                calculateFinanceValues(props.car, true, (localStorage.getItem('tax') / 100))
                localStorage.setItem('includeTaxFees', true)
            }
            else
            {
                localStorage.setItem('includeTaxFees', true)
                props.setTaxFeeModal(true)
            }
        }
    }

    const calculateTotalDiscounts = (car) => {
        if (car === '') {return 0}
        let total = 0
        car?.discounts.map((item) => {
            total = total + item.price;
        })
        return total;
    }

    return (
        <div style={{display: 'flex', flexDirection: 'column', height: 'auto', fontFamily: 'inter', color: window.colors.primary, ...props.style, width: window.innerWidth < 1500 && '98%', marginLeft: window.innerWidth < 1500 && 'auto', marginRight: window.innerWidth < 1500 && 'auto', alignItems: window.innerWidth < 1500 && 'center'}}>
            
            <Modal isOpen={props.taxFeeModal} onRequestClose={() => props.setTaxFeeModal(false)} style={{ overlay: { backgroundColor: 'rgba(0, 0, 0, 0.3)' }, content: { top: '50%', left: '50%', right: 'auto', bottom: 'auto', marginRight: '-50%', transform: 'translate(-50%, -50%)', padding: '0px 20px 5px 20px', width: '80%', maxWidth: '500px', borderRadius: '10px', border: '1px solid #ccc' } }}>
                <h2 style={{color: window.colors.primary }}>Tax & Fees Estimator</h2>
                <span style={{color: window.colors.priamry, fontWeight: '400'}}>Please select the state of which the car will be registered to accurately determine the tax & fees.</span>
                <div style={{marginTop: '20px', fontSize: '20px', color: window.colors.primary, fontWeight: '500', marginBottom: '10px', borderTop: '1px solid ' + window.colors.primary, paddingTop: '10px'}}><span>State</span></div>
                <div style={{overflowY: 'auto'}}>
                    <Select value={selectedState} styles={customStyles} onChange={(e) => setSelectedState(e)} options={stateOptions}  placeholder='Select a State'></Select>
                    <div style={{display: 'flex', flexDirection: 'row', marginTop: '160px'}}>
                        <button onClick={() => props.setTaxFeeModal(false)} style={{ padding: '10px 20px', cursor: 'pointer', marginTop: '20px', backgroundColor: window.colors.primary, border: 'none', color: window.colors.white, fontFamily: 'inter', borderRadius: '5px', marginBottom: '10px' }}>Close</button>
                        <button onClick={() => {props.setIncludeTaxFees(true); props.setTaxFeeModal(false); getRates(selectedState, true)}} style={{ padding: '10px 20px', cursor: 'pointer', marginTop: '20px', backgroundColor: window.colors.primary, border: 'none', color: window.colors.white, fontFamily: 'inter', borderRadius: '5px', marginBottom: '10px', marginLeft: 'auto'}}>Save</button>
                    </div>
                </div>
            </Modal>

            <span style={{fontWeight: '700', fontSize: '32px'}}>Lease Breakdown</span>
            <div style={{display: 'flex', flexDirection: window.innerWidth < 1500 ? 'column':'row', width: window.innerWidth < 1500 && '95%', marginTop: '0px'}}>
                <div style={{display: 'flex', flexDirection: 'column', marginTop: '20px', marginLeft: window.innerWidth < 1500 && 'auto', marginRight: window.innerWidth < 1500 && 'auto', marginTop: window.innerWidth < 900 && '30px', borderRadius: '10px', color: window.colors.primary, width: '100%'}}>
                    <div style={{display: 'flex', flexDirection: window.innerWidth < 900 ? 'column':'row', borderBottom: '1px solid ' + window.colors.primary}}>
                        <span className='noSelect' style={{fontWeight: '500', marginBottom: '20px'}}>All taxes & fees included and assumes credit score of 750+</span>
                    </div>
                    <div style={{display: 'flex', flexDirection: window.innerWidth < 900 ? 'column':'row', color: window.colors.primary, marginTop: '15px'}}>
                        <div style={{display: 'flex', flexDirection: 'column', marginTop: '10px', borderLeft: window.innerWidth < 650 && '1px solid ' + window.colors.white, boxShadow: '0px 0px 10px 1px rgba(0,0,0,0.1)', padding: '15px', borderRadius: '10px', marginRight: '20px', width: '90%'}}>
                            <div style={{display: 'flex', flexDirection: 'row'}}>
                                <span style={{fontWeight: '600', color: window.colors.primary, fontSize: '16px', marginRight: 'auto'}}>Monthly</span>
                                <div className="center" onMouseEnter={() => setNoticePopup(1)} onMouseLeave={() => setNoticePopup(-1)} style={{ fontWeight: '500', fontSize: '16px', backgroundColor: window.colors.primary, borderRadius: '100px', color: window.colors.white, width: '20px', height: '20px', cursor: 'pointer', position: 'relative', marginLeft: '10px' }}>
                                    <span>?</span>
                                    <Tooltip style={{left: window.innerWidth < 900 ? '-300px':'-150px'}} text={'This is the amount you`ll pay each month for the lease, not including taxes, fees, or interest unless selected.'} isVisible={noticePopup === 1}/>
                                </div>
                            </div>
                            <span style={{fontWeight: '400', fontSize: '16px', marginTop: 'auto', paddingTop: '10px'}}>${window.addCommas(props?.car?.lease_monthly)} <span style={{fontWeight: '400', color: window.colors.muted, fontSize:'14px'}}>/month</span></span>
                        </div>
                        <div style={{display: 'flex', flexDirection: 'column', marginTop: '10px', borderLeft: window.innerWidth < 650 && '1px solid ' + window.colors.white, boxShadow: '0px 0px 10px 1px rgba(0,0,0,0.1)', padding: '15px', borderRadius: '10px', marginRight: '20px', width: '90%'}}>
                            <div style={{display: 'flex', flexDirection: 'row'}}>
                                <span style={{fontWeight: '600', color: window.colors.primary, fontSize: '16px', marginRight: 'auto'}}>Due at signing</span>
                                <div className="center" onMouseEnter={() => setNoticePopup(2)} onMouseLeave={() => setNoticePopup(-1)} style={{ fontWeight: '500', fontSize: '16px', backgroundColor: window.colors.primary, borderRadius: '100px', color: window.colors.white, width: '20px', height: '20px', cursor: 'pointer', position: 'relative', marginLeft: '10px' }}>
                                    <span>?</span>
                                    <Tooltip style={{left: window.innerWidth < 900 ? '-300px':'-150px'}} text={'The upfront amount required when signing the lease. This includes taxes, fees, and other charges to start the lease.'} isVisible={noticePopup === 2}/>
                                </div>
                            </div>
                            <span style={{fontWeight: '400', fontSize: '16px', marginTop: 'auto', paddingTop: '5px'}}>${window.addCommas(props?.car?.lease_down)}</span>
                        </div>
                        <div style={{display: 'flex', flexDirection: 'column', marginTop: '10px', borderLeft: window.innerWidth < 650 && '1px solid ' + window.colors.white, boxShadow: '0px 0px 10px 1px rgba(0,0,0,0.1)', padding: '15px', borderRadius: '10px', marginRight: '20px', width: '90%'}}>
                            <div style={{display: 'flex', flexDirection: 'row'}}>
                                <span style={{fontWeight: '600', color: window.colors.primary, fontSize: '16px', marginRight: 'auto'}}>Term Length</span>
                                <div className="center" onMouseEnter={() => setNoticePopup(3)} onMouseLeave={() => setNoticePopup(-1)} style={{ fontWeight: '500', fontSize: '16px', backgroundColor: window.colors.primary, borderRadius: '100px', color: window.colors.white, width: '20px', height: '20px', cursor: 'pointer', position: 'relative', marginLeft: '10px' }}>
                                    <span>?</span>
                                    <Tooltip style={{left: window.innerWidth < 900 ? '-300px':'-150px'}} text={'The duration of the lease agreement, measured in months.'} isVisible={noticePopup === 3}/>
                                </div>
                            </div>
                            <span style={{fontWeight: '400', fontSize: '16px', marginTop: 'auto', paddingTop: '5px'}}>{props?.car?.lease_term_length} Months</span>
                        </div>
                        <div style={{display: 'flex', flexDirection: 'column', marginTop: '10px', borderLeft: window.innerWidth < 650 && '1px solid ' + window.colors.white, boxShadow: '0px 0px 10px 1px rgba(0,0,0,0.1)', padding: '15px', borderRadius: '10px', marginRight: '20px',width: '90%'}}>
                            <div style={{display: 'flex', flexDirection: 'row'}}>
                                <span style={{fontWeight: '600', color: window.colors.primary, fontSize: '16px', marginRight: 'auto'}}>Mileage</span>
                                <div className="center" onMouseEnter={() => setNoticePopup(4)} onMouseLeave={() => setNoticePopup(-1)} style={{ fontWeight: '500', fontSize: '16px', backgroundColor: window.colors.primary, borderRadius: '100px', color: window.colors.white, width: '20px', height: '20px', cursor: 'pointer', position: 'relative', marginLeft: '10px' }}>
                                    <span>?</span>
                                    <Tooltip style={{left: window.innerWidth < 900 ? '-300px':'-150px'}} text={'The maximum number of miles you`re allowed to drive each year without incurring extra fees.'} isVisible={noticePopup === 4}/>
                                </div>
                            </div>
                            <span style={{fontWeight: '400', fontSize: '16px', marginTop: 'auto', paddingTop: '5px'}}>{window.addCommas(props?.car?.yearly_mileage)}</span>
                        </div>
                        <div style={{display: 'flex', flexDirection: 'column', marginTop: '10px', borderLeft: window.innerWidth < 650 && '1px solid ' + window.colors.white, boxShadow: '0px 0px 10px 1px rgba(0,0,0,0.1)', padding: '15px', borderRadius: '10px', marginRight: '20px',width: '90%'}}>
                            <div style={{display: 'flex', flexDirection: 'row'}}>
                                <span style={{fontWeight: '600', color: window.colors.primary, fontSize: '16px', marginRight: 'auto'}}>Discounts</span>
                                <div className="center" onMouseEnter={() => setNoticePopup(5)} onMouseLeave={() => setNoticePopup(-1)} style={{ fontWeight: '500', fontSize: '16px', backgroundColor: window.colors.primary, borderRadius: '100px', color: window.colors.white, width: '20px', height: '20px', cursor: 'pointer', position: 'relative', marginLeft: '10px' }}>
                                    <span>?</span>
                                    <Tooltip style={{left: window.innerWidth < 900 ? '-300px':'-150px'}} text={'Any price reductions or incentives that lower your overall lease cost.'} isVisible={noticePopup === 5}/>
                                </div>
                            </div>
                            <span style={{fontWeight: '400', fontSize: '16px', marginTop: 'auto', paddingTop: '5px'}}>-${window.addCommas(calculateTotalDiscounts(props?.car))}</span>
                        </div>
                        <div style={{display: 'flex', flexDirection: 'column', marginTop: '10px', borderLeft: window.innerWidth < 650 && '1px solid ' + window.colors.white, boxShadow: '0px 0px 10px 1px rgba(0,0,0,0.1)', padding: '15px', borderRadius: '10px', marginRight: '0px',width: '90%'}}>
                            <div style={{display: 'flex', flexDirection: 'row'}}>
                                <span style={{fontWeight: '600', color: window.colors.primary, fontSize: '16px', marginRight: 'auto'}}>Money Factor (APR)</span>
                                <div className="center" onMouseEnter={() => setNoticePopup(6)} onMouseLeave={() => setNoticePopup(-1)} style={{ fontWeight: '500', fontSize: '16px', backgroundColor: window.colors.primary, borderRadius: '100px', color: window.colors.white, width: '20px', height: '20px', cursor: 'pointer', position: 'relative', marginLeft: '10px' }}>
                                    <span>?</span>
                                    <Tooltip style={{left: window.innerWidth < 900 ? '-300px':'-200px'}} text={'This is essentially the interest rate for leasing, calculated to affect your monthly payment.'} isVisible={noticePopup === 6}/>
                                </div>
                            </div>
                            <span style={{fontWeight: '400', fontSize: '16px', marginTop: 'auto', paddingTop: '5px'}}>{window.addCommas(props?.car?.money_factor)} ({(props?.car?.money_factor * 2400)?.toFixed(2)}%)</span>
                        </div>
                    </div>
                </div>
            </div>
            <motion.a href='#priceBreakdown' whileHover={window.bAnim} style={{backgroundColor: window.colors.primary, color: window.colors.white, padding: '15px 25px 15px 25px', border: 'none', borderRadius: '5px', cursor: 'pointer', fontWeight: '500', fontFamily: 'inter', marginTop: '20px', width: '250px', textDecoration: 'none', textAlign: 'center'}}>View Full Price Breakdown</motion.a>
        </div>
    );
}

const Tooltip = ({ text, isVisible, style }) => (
    <AnimatePresence>
        {isVisible && (
            <motion.div
                initial={{ opacity: 0, y: -10 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -10 }}
                transition={{ duration: 0.2 }}
                style={{
                    position: 'absolute',
                    backgroundColor: 'white',
                    color: 'black',
                    padding: '10px',
                    borderRadius: '5px',
                    boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
                    zIndex: 150,
                    width: '300px',
                    left: '-150px',
                    fontWeight: '400',
                    ...style,
                }}
            >
                {text}
            </motion.div>
        )}
    </AnimatePresence>
);

export default Main;
