import React, { Component, useState, useEffect, useRef, useLayoutEffect } from 'react'
import { Link, useNavigate, useLocation } from "react-router-dom"

import TheProcess from '../elements/TheProcess'
import Navbar from '../elements/Navbar'
import Footer from '../elements/Footer'
import VehicleHero from '../elements/VehicleHero'
import VehicleFinance from '../elements/VehicleFinance'
import VehicleFeatures from '../elements/VehicleFeatures'
import VehiclePriceBreakdown from '../elements/VehiclePriceBreakdown'
import VehiclePurchase from '../elements/VehiclePurchase'

const Home = (props) => {

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const [includeTaxFees, setIncludeTaxFees] = useState(false)
    const [leaseMonthly, setLeaseMonthly] = useState('')
    const [leaseDown, setLeaseDown] = useState('')
    const [financeMonthly, setFinanceMonthly] = useState('')
    const [financeDown, setFinanceDown] = useState('')
    const [taxFeeModal, setTaxFeeModal] = useState(false)

    const [car, setCar] = useState('')

    useEffect(() => {
        if (queryParams.get('id') !== undefined)
        {
            var myHeaders = new Headers();
            myHeaders.append("Accept", "application/json");
            myHeaders.append("Content-Type", "application/json");
        
            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                redirect: 'follow',
                body: JSON.stringify({
                    id: queryParams.get('id')
                })
            }
    
            window.apiRequest('/car/search', requestOptions, (result, status) => {   
                console.log(result)
                if (status === 200)
                {
                    setCar(result.cars[0])      
                } else {}
            })
        }
    },[])


    useEffect(() => {
        window.scrollTo(0,0);
    },[])

    useEffect(() => {
        document.title = 'AutoVitus - ' + car?.year + ' ' + car?.brand + ' ' + car?.model
    }, [car]);

    return (
        <div className='center' style={{width: '99.1vw', height: 'auto', backgroundColor: 'white', display: 'flex', flexDirection: 'column', overflowX: 'hidden'}}>
            <div style={{margin: 'auto', height: '100%', maxWidth: '1500px', width: '100%'}}>
                <Navbar></Navbar>
                <VehicleHero style={{marginTop: '40px'}} car={car}></VehicleHero>
                <VehicleFinance style={{marginTop: '100px'}} car={car} includeTaxFees={includeTaxFees} setIncludeTaxFees={setIncludeTaxFees} leaseMonthly={leaseMonthly} setLeaseMonthly={setLeaseMonthly} leaseDown={leaseDown} setLeaseDown={setLeaseDown} financeMonthly={financeMonthly} setFinanceMonthly={setFinanceMonthly} financeDown={financeDown} setFinanceDown={setFinanceDown} taxFeeModal={taxFeeModal} setTaxFeeModal={setTaxFeeModal}></VehicleFinance>
                <VehicleFeatures style={{marginTop: '150px'}} car={car}></VehicleFeatures>
                <TheProcess style={{marginTop: '150px'}} car={car}></TheProcess>
                <VehiclePriceBreakdown style={{marginTop: '150px'}} car={car} includeTaxFees={includeTaxFees} setIncludeTaxFees={setIncludeTaxFees}  leaseMonthly={leaseMonthly} setLeaseMonthly={setLeaseMonthly} leaseDown={leaseDown} setLeaseDown={setLeaseDown} financeMonthly={financeMonthly} setFinanceMonthly={setFinanceMonthly} financeDown={financeDown} setFinanceDown={setFinanceDown} taxFeeModal={taxFeeModal} setTaxFeeModal={setTaxFeeModal}></VehiclePriceBreakdown>
                <VehiclePurchase style={{margin: 'auto', marginTop: '150px'}} car={car}></VehiclePurchase>
                <Footer style={{marginTop: '100px'}}></Footer>
            </div>
        </div>
    )
}

export default Home
