import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from "framer-motion";
import { Link } from "react-router-dom";

import hero from '../media/hero.png'
import heroMap from '../media/heroMap.png'
import suv from '../media/SUV.png'
import FullLogo from '../media/FullLogo.png'
import X from '../media/x-white.png'

const Main = (props) => {

    const links = [
        {
            url: '/',
            title: 'Home'
        },
        {
            url: '/feedback',
            title: 'Leave us feedback!'
        },
        {
            url: '/the-process',
            title: 'The Process'
        },
        {
            url: '/contact-us',
            title: 'Contact Us'
        },
        {
            url: '/deal-finder',
            title: 'Deal Finder'
        },
        {
            url: '/shop',
            title: 'Shop'
        },
    ]

    const [menu, setMenu] = useState(false)

    return (
        <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', ...props.style, marginRight: '20px', width: '100%'}}>
            {window.innerWidth > 800 ? links.map((item, index) => {
                return (
                    index === 0 ? <Link to='/'><img src={FullLogo} style={{height: '74px', marginLeft: '20px'}}></img></Link>:<Link to={item.url} style={{textDecoration: 'none', color: window.colors.primary, fontWeight: '500', marginLeft: index === 1 && 'auto', marginRight: '40px', fontSize: '16px'}}><motion.span whileHover={window.underline}>{item.title}</motion.span></Link>
                )
            }):<>
                <Link to='/'><img src={FullLogo} style={{height: '74px', marginLeft: '20px'}}></img></Link>
                <motion.div onClick={() => setMenu(true)} style={{display: 'flex', flexDirection: 'column', width: '50px', height: '75px', marginLeft: 'auto', marginTop: '30px', translateY: '15px', cursor: 'pointer'}}>
                    <div style={{width: '60%', height: '4px', backgroundColor: window.colors.primary, borderRadius: '100px'}}></div>
                    <div style={{width: '60%', height: '4px', backgroundColor: window.colors.primary, marginTop: '5px', borderRadius: '100px'}}></div>
                    <div style={{width: '60%', height: '4px', backgroundColor: window.colors.primary, marginTop: '5px', borderRadius: '100px'}}></div>
                </motion.div>

                {menu && <div style={{position: 'absolute', top: '0px', left: '0px', width: '100%', backgroundColor: window.colors.primary, display: 'flex', flexDirection: 'column', alignItems: 'start', zIndex: 999999999999}}>
                    {links.map((item, index) => {
                        return (
                            <Link to={item.url} style={{textDecoration: 'none', color: window.colors.primary, fontWeight: '600', padding: '20px', color: window.colors.white, borderBottom: '1px solid ' + window.colors.white, width: '100%'}}><motion.span whileHover={window.underline}>{item.title}</motion.span></Link>
                        )
                    })}
                    <div onClick={() => setMenu(false)} style={{textDecoration: 'none', color: window.colors.primary, fontWeight: '600', padding: '20px', color: window.colors.white, borderBottom: '1px solid ' + window.colors.white, width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center', cursor: 'pointer'}}><img src={X} style={{width: '20px'}}></img><motion.span whileHover={window.underline} style={{marginLeft: '10px'}}>Close</motion.span></div>
                </div>}
            </>}
        </div>
    );
}

export default Main;
