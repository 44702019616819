import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from "framer-motion";

import hero from '../media/hero.png'
import heroMap from '../media/heroMap.png'
import suv from '../media/SUV.png'
import search from '../media/search.png'
import coupe from '../media/coupe.png'
import sedan from '../media/sedan.png'
import truck from '../media/truck.png'

import { Link } from 'react-router-dom'

const Main = (props) => {

    const bodyStyles = ['SUV','Sedan','Truck','Coupe']
    const bodyImages = [suv, sedan, truck, coupe]

    const [searchTerm, setSearchTerm] = useState('')

    return (
        <div style={{fontFamily: 'inter', display: 'flex', flexDirection: 'column', color: window.colors.primary, ...props.style}}>
            <div style={{ fontFamily: 'Arial, sans-serif', padding: '20px', lineHeight: '1.6', color: '#333' }}>
                <h1 style={{ textAlign: 'center', fontSize: '28px', marginBottom: '10px' }}>TERMS OF USE</h1>
                <p style={{ textAlign: 'center', fontSize: '14px', marginBottom: '30px' }}>Last updated 10/16/2024</p>

                <h2 style={{ fontSize: '24px', marginBottom: '10px' }}>AGREEMENT TO OUR LEGAL TERMS</h2>
                <p>
                We are <strong>Thinetics LLC</strong> ("Company," "we," "us," "our"). We operate, as well as any other related products and services that refer or 
                link to these legal terms (the "Legal Terms") (collectively, the "Services").
                </p>
                <p>You can contact us by email at <a href="mailto:support@thinetics.io" style={{ color: '#1a73e8' }}>support@thinetics.io</a>.</p>
                <p>
                These Legal Terms constitute a legally binding agreement made between you, whether personally or on behalf of an entity ("you"), and Thinetics LLC, 
                concerning your access to and use of the Services. By accessing the Services, you agree that you have read, understood, and agreed to be bound by these Legal Terms. 
                <strong>IF YOU DO NOT AGREE WITH ALL OF THESE LEGAL TERMS, THEN YOU ARE EXPRESSLY PROHIBITED FROM USING THE SERVICES AND YOU MUST DISCONTINUE USE IMMEDIATELY.</strong>
                </p>
                <p>
                Supplemental terms and conditions or documents that may be posted on the Services from time to time are hereby expressly incorporated by reference. 
                We reserve the right, in our sole discretion, to make changes or modifications to these Legal Terms at any time and for any reason. 
                We will alert you about any changes by updating the "Last updated" date of these Legal Terms. You waive any right to receive specific notice of each change. 
                It is your responsibility to periodically review these Legal Terms to stay informed of updates. 
                Your continued use of the Services after posting revised Legal Terms will constitute your acceptance of those changes.
                </p>
                <p>We recommend that you print a copy of these Legal Terms for your records.</p>

                <h2 style={{ fontSize: '24px', marginBottom: '10px' }}>TABLE OF CONTENTS</h2>
                <ol style={{ paddingLeft: '20px', marginBottom: '20px' }}>
                <li>OUR SERVICES</li>
                <li>INTELLECTUAL PROPERTY RIGHTS</li>
                <li>USER REPRESENTATIONS</li>
                <li>PROHIBITED ACTIVITIES</li>
                <li>USER GENERATED CONTRIBUTIONS</li>
                <li>CONTRIBUTION LICENSE</li>
                <li>SERVICES MANAGEMENT</li>
                <li>TERM AND TERMINATION</li>
                <li>MODIFICATIONS AND INTERRUPTIONS</li>
                <li>GOVERNING LAW</li>
                <li>DISPUTE RESOLUTION</li>
                <li>CORRECTIONS</li>
                <li>DISCLAIMER</li>
                <li>LIMITATIONS OF LIABILITY</li>
                <li>INDEMNIFICATION</li>
                <li>USER DATA</li>
                <li>ELECTRONIC COMMUNICATIONS, TRANSACTIONS, AND SIGNATURES</li>
                <li>MISCELLANEOUS</li>
                <li>CONTACT US</li>
                </ol>
            </div>
            <div style={{ fontFamily: 'Arial, sans-serif', padding: '20px', lineHeight: '1.6', color: '#333' }}>


                <h2 style={{ fontSize: '24px', marginBottom: '10px' }}>1. OUR SERVICES</h2>
                <p>
                The information provided when using the Services is not intended for distribution to or use by any person or entity in any jurisdiction or 
                country where such distribution or use would be contrary to law or regulation or which would subject us to any registration requirement within 
                such jurisdiction or country. Accordingly, those persons who choose to access the Services from other locations do so on their own initiative 
                and are solely responsible for compliance with local laws, if and to the extent local laws are applicable.
                </p>

                <h2 style={{ fontSize: '24px', marginBottom: '10px' }}>2. INTELLECTUAL PROPERTY RIGHTS</h2>

                <h3 style={{ fontSize: '20px', marginBottom: '5px' }}>Our intellectual property</h3>
                <p>
                We are the owner or the licensee of all intellectual property rights in our Services, including all source code, databases, functionality, 
                software, website designs, audio, video, text, photographs, and graphics in the Services (collectively, the "Content"), as well as the trademarks, 
                service marks, and logos contained therein (the "Marks").
                </p>
                <p>
                Our Content and Marks are protected by copyright and trademark laws (and various other intellectual property rights and unfair competition laws) 
                and treaties in the United States and around the world. The Content and Marks are provided "AS IS" for your personal, non-commercial use or 
                internal business purpose only.
                </p>

                <h3 style={{ fontSize: '20px', marginBottom: '5px' }}>Your use of our Services</h3>
                <p>
                Subject to your compliance with these Legal Terms, including the "PROHIBITED ACTIVITIES" section below, we grant you a non-exclusive, non-transferable, 
                revocable license to:
                </p>
                <ul style={{ listStyle: 'disc', paddingLeft: '20px' }}>
                <li>Access the Services; and</li>
                <li>Download or print a copy of any portion of the Content to which you have properly gained access solely for your personal, non-commercial use or internal business purpose.</li>
                </ul>
                <p>
                No part of the Services, Content, or Marks may be copied, reproduced, aggregated, republished, uploaded, posted, publicly displayed, encoded, translated, 
                transmitted, distributed, sold, licensed, or otherwise exploited for any commercial purpose without our express prior written permission.
                </p>
                <p>
                If you wish to make any use of the Services, Content, or Marks beyond the terms provided, please contact us at: support@thinetics.io. 
                Any permission granted must identify us as the owners or licensors, and any copyright or proprietary notice must be visible.
                </p>
                <p>
                We reserve all rights not expressly granted to you in and to the Services, Content, and Marks. A breach of these Intellectual Property Rights will 
                result in immediate termination of your right to use our Services.
                </p>

                <h3 style={{ fontSize: '20px', marginBottom: '5px' }}>Your submissions</h3>
                <p>
                Please review this section and the "PROHIBITED ACTIVITIES" section carefully before using our Services to understand your rights and obligations 
                when posting or uploading content.
                </p>
                <p>
                <strong>Submissions:</strong> By sending us any questions, comments, suggestions, ideas, feedback, or other information about the Services ("Submissions"), 
                you agree to assign all intellectual property rights in such Submissions to us. We will own the Submissions and may use or disseminate them for any lawful purpose 
                without acknowledgment or compensation to you.
                </p>

                <h3 style={{ fontSize: '20px', marginBottom: '5px' }}>Your responsibilities</h3>
                <p>
                By sending us Submissions, you:
                </p>
                <ul style={{ listStyle: 'disc', paddingLeft: '20px' }}>
                <li>
                    Confirm that you have read and agree with our "PROHIBITED ACTIVITIES" section and will not post or upload anything that is illegal, harassing, 
                    hateful, harmful, defamatory, obscene, discriminatory, threatening, sexually explicit, or misleading.
                </li>
                <li>Waive any and all moral rights to such Submissions to the extent permissible by law.</li>
                <li>
                    Warrant that your Submissions are original to you or that you have the necessary rights and licenses to submit them and grant us the rights described above.
                </li>
                <li>Warrant that your Submissions do not contain confidential information.</li>
                </ul>
                <p>
                You are solely responsible for your Submissions and agree to reimburse us for any losses we suffer as a result of your breach of this section, 
                any third party’s intellectual property rights, or applicable law.
                </p>
            </div>
            <div style={{ fontFamily: 'Arial, sans-serif', padding: '20px', lineHeight: '1.6', color: '#333' }}>

                <h2 style={{ fontSize: '24px', marginBottom: '10px' }}>3. USER REPRESENTATIONS</h2>
                <p>
                By using the Services, you represent and warrant that: 
                </p>
                <ul style={{ listStyle: 'disc', paddingLeft: '20px' }}>
                <li>You have the legal capacity and agree to comply with these Legal Terms.</li>
                <li>You are not a minor in the jurisdiction in which you reside.</li>
                <li>You will not access the Services through automated or non-human means, such as a bot or script.</li>
                <li>You will not use the Services for any illegal or unauthorized purpose.</li>
                <li>Your use of the Services will not violate any applicable law or regulation.</li>
                </ul>
                <p>
                If you provide any information that is untrue, inaccurate, not current, or incomplete, we have the right to suspend or terminate 
                your account and refuse any and all current or future use of the Services (or any portion thereof).
                </p>

                <h2 style={{ fontSize: '24px', marginBottom: '10px' }}>4. PROHIBITED ACTIVITIES</h2>
                <p>
                You may not access or use the Services for any purpose other than that for which we make the Services available. 
                The Services may not be used in connection with any commercial endeavors except those specifically endorsed or approved by us.
                </p>
                <p>As a user of the Services, you agree not to:</p>
                <ul style={{ listStyle: 'disc', paddingLeft: '20px' }}>
                <li>Systematically retrieve data or content to create a collection, compilation, database, or directory without our permission.</li>
                <li>Trick, defraud, or mislead us or other users to obtain sensitive account information, such as passwords.</li>
                <li>Circumvent or interfere with security-related features of the Services.</li>
                <li>Disparage, tarnish, or harm us or the Services in any way.</li>
                <li>Use information from the Services to harass, abuse, or harm another person.</li>
                <li>Make improper use of our support services or submit false reports of abuse or misconduct.</li>
                <li>Engage in unauthorized framing of or linking to the Services.</li>
                <li>
                    Upload or transmit viruses, Trojan horses, or other material that interferes with the use and enjoyment of the Services.
                </li>
                <li>Engage in automated use of the system, such as using scripts or bots to send messages or mine data.</li>
                <li>Delete any copyright or proprietary rights notice from the Content.</li>
                <li>Impersonate another user or use another user’s username.</li>
                <li>
                    Upload or transmit passive or active information collection mechanisms, such as spyware, web bugs, or cookies.
                </li>
                <li>Interfere with or disrupt the Services or networks connected to the Services.</li>
                <li>Harass, annoy, intimidate, or threaten our employees or agents.</li>
                <li>Attempt to bypass measures designed to restrict access to the Services.</li>
                <li>Copy or adapt the Services' software, including HTML, JavaScript, or other code.</li>
                <li>Decipher, decompile, disassemble, or reverse engineer any of the software comprising the Services.</li>
                <li>
                    Use unauthorized automated systems, such as spiders, scrapers, or bots, to access the Services.
                </li>
                <li>Use a buying agent or purchasing agent to make purchases on the Services.</li>
                <li>
                    Collect usernames or email addresses for sending unsolicited emails or create user accounts under false pretenses.
                </li>
                <li>Use the Services for any revenue-generating endeavor or commercial enterprise without our consent.</li>
                </ul>

                <h2 style={{ fontSize: '24px', marginBottom: '10px' }}>5. USER GENERATED CONTRIBUTIONS</h2>
                <p>
                The Services do not currently offer users the ability to submit or post content. However, we may provide you with the opportunity 
                to create, submit, post, display, transmit, perform, publish, distribute, or broadcast content and materials to us or on the Services 
                (collectively, "Contributions"). Contributions may include, but are not limited to, text, writings, video, audio, photographs, graphics, 
                comments, suggestions, or personal information.
                </p>
                <p>
                Contributions may be viewable by other users of the Services and through third-party websites. When you create or make available 
                any Contributions, you represent and warrant that:
                </p>
                <ul style={{ listStyle: 'disc', paddingLeft: '20px' }}>
                <li>Your Contributions are not false, inaccurate, or misleading.</li>
                <li>Your Contributions do not violate any applicable law or regulation.</li>
                <li>Your Contributions do not infringe any third-party rights, including intellectual property rights and privacy rights.</li>
                <li>
                    You are the creator and owner of your Contributions or have the necessary rights and permissions to use and authorize us 
                    to use your Contributions.
                </li>
                <li>
                    Your Contributions do not contain any material that is unlawful, harassing, defamatory, abusive, discriminatory, or obscene.
                </li>
                <li>
                    Your Contributions do not contain unauthorized advertising, promotional materials, spam, or chain letters.
                </li>
                <li>
                    Your Contributions do not exploit or harm minors in any way, including through exposure to inappropriate content.
                </li>
                <li>Your Contributions do not impersonate any person or misrepresent your identity or affiliation.</li>
                <li>
                    You will not upload or transmit any material that contains viruses, malware, or other harmful code.
                </li>
                <li>
                    Your Contributions do not violate the privacy or publicity rights of any third party.
                </li>
                </ul>
                <p>
                Any violation of these terms may result in the termination or suspension of your rights to use the Services.
                </p>
            </div>
            <div style={{ fontFamily: 'Arial, sans-serif', padding: '20px', lineHeight: '1.6', color: '#333' }}>

                <h2 style={{ fontSize: '24px', marginBottom: '10px' }}>6. CONTRIBUTION LICENSE</h2>
                <p>
                You and the Services agree that we may access, store, process, and use any information and personal data that you provide and your choices (including settings).
                </p>
                <p>
                By submitting suggestions or feedback regarding the Services, you agree that we can use and share such feedback for any purpose without compensation to you.
                </p>
                <p>
                We do not assert ownership over your Contributions. You retain full ownership of your Contributions and any associated intellectual property rights. 
                We are not liable for any statements or representations in your Contributions. You are solely responsible for your Contributions, and you agree to exonerate us from any liability related to your Contributions.
                </p>

                <h2 style={{ fontSize: '24px', marginBottom: '10px' }}>7. SERVICES MANAGEMENT</h2>
                <p>We reserve the right to:</p>
                <ul style={{ listStyle: 'disc', paddingLeft: '20px' }}>
                <li>Monitor the Services for violations of these Legal Terms.</li>
                <li>Take legal action against anyone violating these Legal Terms or the law.</li>
                <li>Refuse or limit access to any portion of the Services at our sole discretion.</li>
                <li>Remove excessive or burdensome files and content.</li>
                <li>Manage the Services to protect our rights and property and ensure proper functionality.</li>
                </ul>

                <h2 style={{ fontSize: '24px', marginBottom: '10px' }}>8. TERM AND TERMINATION</h2>
                <p>
                These Legal Terms remain in effect while you use the Services. We reserve the right to deny access to the Services, including blocking certain IP addresses, without notice or liability, for any reason, including violation of these Legal Terms or applicable law.
                </p>
                <p>
                If your account is terminated or suspended, you are prohibited from creating a new account under your name or any third party’s name. We may take legal action, including pursuing civil, criminal, or injunctive redress, as appropriate.
                </p>

                <h2 style={{ fontSize: '24px', marginBottom: '10px' }}>9. MODIFICATIONS AND INTERRUPTIONS</h2>
                <p>
                We reserve the right to change, modify, or remove content from the Services at any time, without notice. We are not obligated to update information on the Services and are not liable for any changes, price adjustments, or discontinuation of the Services.
                </p>
                <p>
                We do not guarantee the Services will be available at all times. Maintenance, hardware, software issues, or other problems may result in interruptions. We are not liable for any inconvenience or loss resulting from such interruptions.
                </p>

                <h2 style={{ fontSize: '24px', marginBottom: '10px' }}>10. GOVERNING LAW</h2>
                <p>
                These Legal Terms shall be governed by and defined following the laws of The United States of America. The courts of The United States of America shall have exclusive jurisdiction to resolve disputes related to these Legal Terms.
                </p>

                <h2 style={{ fontSize: '24px', marginBottom: '10px' }}>11. DISPUTE RESOLUTION</h2>

                <h3 style={{ fontSize: '20px', marginBottom: '5px' }}>Informal Negotiations</h3>
                <p>
                To resolve disputes efficiently, the Parties agree to negotiate disputes informally for 180 days before initiating arbitration.
                </p>

                <h3 style={{ fontSize: '20px', marginBottom: '5px' }}>Binding Arbitration</h3>
                <p>
                Any dispute shall be referred to the International Commercial Arbitration Court under the European Arbitration Chamber (Belgium, Brussels, Avenue Louise, 146) according to its rules. The number of arbitrators, seat of arbitration, language, and governing law will be determined as specified in these Legal Terms.
                </p>

                <h3 style={{ fontSize: '20px', marginBottom: '5px' }}>Restrictions</h3>
                <p>
                Arbitration will be limited to the Parties involved, with no class action or representative proceedings permitted. If any portion of the arbitration clause is found unenforceable, the dispute will be resolved by a court of competent jurisdiction.
                </p>

                <h3 style={{ fontSize: '20px', marginBottom: '5px' }}>Exceptions to Informal Negotiations and Arbitration</h3>
                <p>
                The following disputes are not subject to arbitration: disputes related to intellectual property rights, theft, piracy, privacy violations, or claims for injunctive relief. If arbitration is not enforceable, the dispute will be resolved in the courts listed under "Governing Law."
                </p>
            </div>
            <div style={{ fontFamily: 'Arial, sans-serif', padding: '20px', lineHeight: '1.6', color: '#333' }}>

                <h2 style={{ fontSize: '24px', marginBottom: '10px' }}>12. CORRECTIONS</h2>
                <p>
                There may be information on the Services that contains typographical errors, inaccuracies, or omissions, including descriptions, 
                pricing, availability, and other information. We reserve the right to correct any errors, inaccuracies, or omissions and to change or update 
                the information on the Services at any time, without prior notice.
                </p>

                <h2 style={{ fontSize: '24px', marginBottom: '10px' }}>13. DISCLAIMER</h2>
                <p>
                THE SERVICES ARE PROVIDED ON AN "AS-IS" AND "AS-AVAILABLE" BASIS. YOU AGREE THAT YOUR USE OF THE SERVICES WILL BE AT YOUR SOLE RISK. 
                TO THE FULLEST EXTENT PERMITTED BY LAW, WE DISCLAIM ALL WARRANTIES, EXPRESS OR IMPLIED, IN CONNECTION WITH THE SERVICES AND YOUR USE THEREOF, 
                INCLUDING THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND NON-INFRINGEMENT.
                </p>
                <p>
                We make no warranties or representations about the accuracy or completeness of the content on the Services or any websites or applications 
                linked to the Services. We assume no liability for:
                </p>
                <ul style={{ listStyle: 'disc', paddingLeft: '20px' }}>
                <li>Errors, mistakes, or inaccuracies of content and materials.</li>
                <li>Personal injury or property damage resulting from your access to and use of the Services.</li>
                <li>Unauthorized access to or use of our servers and personal or financial information stored therein.</li>
                <li>Any interruption or cessation of transmission to or from the Services.</li>
                <li>Bugs, viruses, or other harmful code transmitted through the Services by third parties.</li>
                <li>Errors or omissions in any content or materials.</li>
                </ul>
                <p>
                We do not warrant or assume responsibility for any product or service advertised or offered by a third party through the Services or any 
                linked website or application. We will not be a party to any transaction between you and third-party providers. Use your best judgment 
                and exercise caution where appropriate.
                </p>

                <h2 style={{ fontSize: '24px', marginBottom: '10px' }}>14. LIMITATIONS OF LIABILITY</h2>
                <p>
                IN NO EVENT WILL WE OR OUR DIRECTORS, EMPLOYEES, OR AGENTS BE LIABLE TO YOU OR ANY THIRD PARTY FOR ANY INDIRECT, CONSEQUENTIAL, EXEMPLARY, 
                INCIDENTAL, SPECIAL, OR PUNITIVE DAMAGES, INCLUDING LOST PROFITS, LOST REVENUE, OR LOSS OF DATA, ARISING FROM YOUR USE OF THE SERVICES, 
                EVEN IF WE HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.
                </p>
                <p>
                OUR LIABILITY TO YOU FOR ANY CAUSE WILL BE LIMITED TO THE LESSER OF THE AMOUNT PAID BY YOU TO US OR $1. SOME JURISDICTIONS DO NOT ALLOW THE 
                EXCLUSION OF CERTAIN WARRANTIES OR LIMITATIONS ON DAMAGES. IN SUCH CASES, SOME OR ALL OF THE ABOVE LIMITATIONS MAY NOT APPLY TO YOU, 
                AND YOU MAY HAVE ADDITIONAL RIGHTS.
                </p>

                <h2 style={{ fontSize: '24px', marginBottom: '10px' }}>15. INDEMNIFICATION</h2>
                <p>
                You agree to defend, indemnify, and hold us harmless, including our subsidiaries, affiliates, officers, agents, partners, and employees, 
                from any loss, damage, liability, claim, or demand, including reasonable attorneys' fees, made by any third party due to or arising from:
                </p>
                <ul style={{ listStyle: 'disc', paddingLeft: '20px' }}>
                <li>Your use of the Services.</li>
                <li>Your breach of these Legal Terms.</li>
                <li>Your violation of any third-party rights, including intellectual property rights.</li>
                <li>Any harmful act toward other users connected via the Services.</li>
                </ul>
                <p>
                We reserve the right to assume exclusive defense and control of any matter subject to indemnification at your expense, and you agree to 
                cooperate with our defense of such claims. We will make reasonable efforts to notify you of any such claim or proceeding.
                </p>
            </div>
            <div style={{ fontFamily: 'Arial, sans-serif', padding: '20px', lineHeight: '1.6', color: '#333' }}>

                <h2 style={{ fontSize: '24px', marginBottom: '10px' }}>12. CORRECTIONS</h2>
                <p>
                There may be information on the Services that contains typographical errors, inaccuracies, or omissions, including descriptions, 
                pricing, availability, and other information. We reserve the right to correct any errors, inaccuracies, or omissions and to change or update 
                the information on the Services at any time, without prior notice.
                </p>

                <h2 style={{ fontSize: '24px', marginBottom: '10px' }}>13. DISCLAIMER</h2>
                <p>
                THE SERVICES ARE PROVIDED ON AN "AS-IS" AND "AS-AVAILABLE" BASIS. YOU AGREE THAT YOUR USE OF THE SERVICES WILL BE AT YOUR SOLE RISK. 
                TO THE FULLEST EXTENT PERMITTED BY LAW, WE DISCLAIM ALL WARRANTIES, EXPRESS OR IMPLIED, IN CONNECTION WITH THE SERVICES AND YOUR USE THEREOF, 
                INCLUDING THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND NON-INFRINGEMENT.
                </p>
                <p>
                We make no warranties or representations about the accuracy or completeness of the content on the Services or any websites or applications 
                linked to the Services. We assume no liability for:
                </p>
                <ul style={{ listStyle: 'disc', paddingLeft: '20px' }}>
                <li>Errors, mistakes, or inaccuracies of content and materials.</li>
                <li>Personal injury or property damage resulting from your access to and use of the Services.</li>
                <li>Unauthorized access to or use of our servers and personal or financial information stored therein.</li>
                <li>Any interruption or cessation of transmission to or from the Services.</li>
                <li>Bugs, viruses, or other harmful code transmitted through the Services by third parties.</li>
                <li>Errors or omissions in any content or materials.</li>
                </ul>
                <p>
                We do not warrant or assume responsibility for any product or service advertised or offered by a third party through the Services or any 
                linked website or application. We will not be a party to any transaction between you and third-party providers. Use your best judgment 
                and exercise caution where appropriate.
                </p>

                <h2 style={{ fontSize: '24px', marginBottom: '10px' }}>14. LIMITATIONS OF LIABILITY</h2>
                <p>
                IN NO EVENT WILL WE OR OUR DIRECTORS, EMPLOYEES, OR AGENTS BE LIABLE TO YOU OR ANY THIRD PARTY FOR ANY INDIRECT, CONSEQUENTIAL, EXEMPLARY, 
                INCIDENTAL, SPECIAL, OR PUNITIVE DAMAGES, INCLUDING LOST PROFITS, LOST REVENUE, OR LOSS OF DATA, ARISING FROM YOUR USE OF THE SERVICES, 
                EVEN IF WE HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.
                </p>
                <p>
                OUR LIABILITY TO YOU FOR ANY CAUSE WILL BE LIMITED TO THE LESSER OF THE AMOUNT PAID BY YOU TO US OR $1. SOME JURISDICTIONS DO NOT ALLOW THE 
                EXCLUSION OF CERTAIN WARRANTIES OR LIMITATIONS ON DAMAGES. IN SUCH CASES, SOME OR ALL OF THE ABOVE LIMITATIONS MAY NOT APPLY TO YOU, 
                AND YOU MAY HAVE ADDITIONAL RIGHTS.
                </p>

                <h2 style={{ fontSize: '24px', marginBottom: '10px' }}>15. INDEMNIFICATION</h2>
                <p>
                You agree to defend, indemnify, and hold us harmless, including our subsidiaries, affiliates, officers, agents, partners, and employees, 
                from any loss, damage, liability, claim, or demand, including reasonable attorneys' fees, made by any third party due to or arising from:
                </p>
                <ul style={{ listStyle: 'disc', paddingLeft: '20px' }}>
                <li>Your use of the Services.</li>
                <li>Your breach of these Legal Terms.</li>
                <li>Your violation of any third-party rights, including intellectual property rights.</li>
                <li>Any harmful act toward other users connected via the Services.</li>
                </ul>
                <p>
                We reserve the right to assume exclusive defense and control of any matter subject to indemnification at your expense, and you agree to 
                cooperate with our defense of such claims. We will make reasonable efforts to notify you of any such claim or proceeding.
                </p>
            </div>
        </div>
    );
}

export default Main;
