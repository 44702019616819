import logo from './logo.svg';
import './App.css';

import React, { Component, useState, useEffect} from 'react'
import { BrowserRouter as Router, Route, Routes, withRouter, BrowserRouter, useLocation  } from "react-router-dom";
import { Switch } from "react-router"
import { NavLink, Link, useNavigate } from "react-router-dom"

import Home from './components/pages/Home'
import Shop from './components/pages/Shop'
import Vehicle from './components/pages/Vehicle'
import LoginTemplate from './components/pages/LoginTemplates'
import ContactUs from './components/pages/Contact-Us'
import DealFinderService from './components/pages/DealFinderService'
import ShareLease from './components/pages/Share-Lease'
import TheProcess from './components/pages/TheProcess'
import Feedback from './components/pages/Feedback'
import PrivacyPolicy from './components/pages/PrivacyPolicy'
import TOS from './components/pages/TOS'
import InputCar from './components/pages/InputCars'
import Newsletter from './components/pages/Newsletter'
import TestPage from './components/pages/TestPage'

import { initializeGA, logPageView } from './analytics';
import { HelmetProvider } from 'react-helmet-async';

function App() {

  window.colors = {
    "black": "#0D0D0D",
    "blackGrey": "#B5B5B5",
    "white": "#FFFFFF",
    "whiteGrey": "#343434",
    "primary": '#030718',
    'border': '#E7E7E7',
    'muted': '#A1A8B3',
    'link': '#5E9CFF',
    'background': '#EDEDEF',
  };

  const baseURL = 'https://api.autovitus.com'
  window.baseURL = baseURL;

  function getCurrentTimeFormatted() {
    const now = new Date();

    let hours = now.getHours();
    const ampm = hours >= 12 ? 'PM' : 'AM';

    hours = hours % 12; // Convert to 12-hour format
    hours = hours ? hours : 12; // The hour '0' should be '12'

    const minutes = now.getMinutes().toString().padStart(2, '0');
    const seconds = now.getSeconds().toString().padStart(2, '0');

    return `${hours.toString().padStart(2, '0')}:${minutes}:${seconds} ${ampm}`;
  }

  const getNewAccessToken = async (refreshToken) => {

    var myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    myHeaders.append("Authorization", "Bearer " + refreshToken); 

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        redirect: 'follow',
    }

    requestOptions.headers.set('Authorization', "Bearer " + refreshToken)
  
    fetch(baseURL + '/auth/refresh', requestOptions)
    .then(response => {responseFromServer = response.status; return response.json()})
    .then(result => {
      console.log(result)
      if (result.detail === 'Invalid Token, please logout and log back in.')
      {
        console.log('Bad Refresh Token also!')
        window.location.assign('/')
        return false;
      }
      else
      {
        console.log('Good Refresh Token')
        localStorage.setItem('access_token', result.access_token)
        localStorage.setItem('access_token_expires', result.access_token_expires)
        return true;
      }
    })
  }

  let responseFromServer = '';

  window.apiRequest = (endpoint, requestOptions, operation) => {
    let accessToken = localStorage.getItem('access_token');
    let refreshToken = localStorage.getItem('refresh_token');
    
    fetch(baseURL + endpoint, requestOptions)
    .then(response => {
        if (!response.ok) {
            if (response.status === 500) {
                return { detail: "Cannot reach server!" };
            }
            throw new Error('Network response was not ok');
        }
        responseFromServer = response.status;
        return response.json();
    })
    .then(result => {
        if (result.detail === 'Invalid Token, please logout and log back in.') {
            console.log('Bad Access Token, getting new one!');
            if (getNewAccessToken(refreshToken)) {
                console.log('continuing operation');
                window.apiRequest(endpoint, requestOptions, operation);
            } else {
                console.log('Did not get new access token');
                window.location.assign('/');
            }
        } else {
            operation(result, responseFromServer);
        }
    })
    .catch(error => {
        console.error('Fetch error:', error);
        operation({ detail: "Cannot reach server!" }, 500);
    });
  }

  window.getServerResponse = (json) => {
    if (typeof json.detail === 'string' || json.detail instanceof String) {
      return (getCurrentTimeFormatted() + ': ' + json.detail)
    } else {
      return json.detail.map((error, index) => {
        return (getCurrentTimeFormatted() + ': ' + error.msg + ' at ' + error.loc.join(', ')).replace(/string/g, "text");
      })
    }
  }

  window.addCommas = (number) => {
    if (number === undefined || number === null) {
      return '';
    }
  
    // Split the number into integer and decimal parts
    const [integerPart, decimalPart] = number.toString().split('.');
  
    // Add commas to the integer part
    const formattedInteger = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  
    // Return the formatted integer part with the decimal part (if it exists)
    return decimalPart ? `${formattedInteger}.${decimalPart}` : formattedInteger;
  }

  window.bAnim = {filter: 'opacity(80%)', transition: {duration: 0.1}}
  window.shadow = {boxShadow: '0px 0px 20px 1px rgb(0,0,0,0.07)', transition: {duration: 0.1}}
  window.underline = {textDecoration: 'underline', transition: {duration: 0.1}}

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  window.width = windowWidth

  useEffect(() => {
    const handleResize = () => {setWindowWidth(window.innerWidth); window.width = window.innerWidth}

    // Add event listener to track window resize
    window.addEventListener('resize', handleResize);

    // Cleanup event listener on component unmount
    return () => window.removeEventListener('resize', handleResize);
  },[])

  const location = useLocation();

  useEffect(() => {
    initializeGA();
  }, []);

  useEffect(() => {
    logPageView(location.pathname + location.search); // Log page view on route change
  }, [location]);

  return (
    <HelmetProvider>
      <Routes>
        <Route path='/' exact element={<Home/>}>

        </Route>
        <Route path='/shop' exact element={<Shop/>}>

        </Route>
        <Route path='/login' exact element={<LoginTemplate/>}>

        </Route>
        <Route path='/vehicle' exact element={<Vehicle/>}>

        </Route>
        <Route path='/contact-us' exact element={<ContactUs/>}>

        </Route>
        <Route path='/deal-finder' exact element={<DealFinderService/>}>

        </Route>
        <Route path='/share-lease' exact element={<ShareLease/>}>

        </Route>
        <Route path='/the-process' exact element={<TheProcess/>}>

        </Route>
        <Route path='/feedback' exact element={<Feedback/>}>

        </Route>
        <Route path='/privacy-policy' exact element={<PrivacyPolicy/>}>

        </Route>
        <Route path='/TOS' exact element={<TOS/>}>

        </Route>
        <Route path='/car-input' exact element={<InputCar/>}>

        </Route>
        <Route path='/newsletter' exact element={<Newsletter/>}>

        </Route>
        {/* <Route path='/test' exact element={<TestPage/>}>

        </Route> */}
      </Routes>
    </HelmetProvider>
  );
}

export default App;
