import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { useLocation } from 'react-router-dom';

import search from '../media/search.png'
import x from '../media/x.svg'
import downArrow from '../media/down-arrow.png'
import featuredCar from '../media/featuredCar.png'
import sideArrow from '../media/side-arrow.svg'

import { Link } from "react-router-dom";

const Main = (props) => {

    const getGridStyle = () => {
        const width = window.innerWidth;
        let columns = 'repeat(3, minmax(300px, 1fr))';
        let gap = '35px';

        if (width <= 1550) {
            columns = 'repeat(2, minmax(300px, 1fr))';
            gap = '35px';
        }

        if (width <= 1000) {
            columns = 'minmax(300px, 1fr)';
            gap = '20px';
        }

        if (width <= 340) {
            columns = '';
            gap = '20px';
        }

        return {
            display: 'grid',
            gridTemplateColumns: columns,
            gap: gap,
            marginTop: '30px',
            width: width < 1550 ? '100%':'auto'
        };
    };

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);

    const [searchTerm, setSearchTerm] = useState('')

    const [cars, setCars] = useState([])

    const calculateLease = (car) => {
        const dpAmount = 0.05
        return window.addCommas((((car.discounted_msrp - car.residual) - (car.discounted_msrp * dpAmount))/car.lease_term_length).toFixed(0))
    }

    const calculateFinance = (car) => {
        const dpAmount = 0.1
        return window.addCommas((((car.discounted_msrp) - (car.discounted_msrp * dpAmount))/car.finance_term_length).toFixed(0))
    }

    function extractStrings(obj) {
        let result = [];
        
        Object.values(obj).forEach(value => {
            if (Array.isArray(value)) {
                result = result.concat(value);
            }
        });
        console.log(result)
        return result;
    }

    function removeStringFromObject(obj, str) {
        // Create a copy of the object to avoid mutating the original state
        const newObj = {...obj};
    
        Object.keys(newObj).forEach(key => {
            if (Array.isArray(newObj[key])) {
                const index = newObj[key].indexOf(str);
                if (index !== -1) {
                    newObj[key] = newObj[key].filter(item => item !== str);  // Create a new array excluding the string
                }
            }
        });
    
        return newObj;  // Return the updated object
    }
    
    const deleteFilter = (key) => {
        console.log(props.selectedOptions)
        // Create a copy of the selected options
        const updatedOptions = { ...props.selectedOptions };
        
        // Set the specified key to null
        delete updatedOptions[key]

        // Update the state with the modified object
        props.setSelectedOptions(updatedOptions);
    }

    const Base64ToImage = ({ base64 }) => {
        return (
          <div>
            <img
              src={`data:image/jpeg;base64,${base64}`}
              alt="Base64 Image"
              style={{ width: '200px', height: '200px' }}
            />
          </div>
        );
    };

    useEffect(() => {
        setSearchTerm(queryParams.get('searchTerm'))
        searchDeals(queryParams.get('searchTerm'))
    },[])

    useEffect(() => {
        searchDeals(searchTerm, props.selectedOptions)
    },[props.page])

    useEffect(() => {
        // Set up a timer to debounce the search
        const handler = setTimeout(() => {
            if (props.page === 1)
            {
                searchDeals(searchTerm, props.selectedOptions)
            }
            else
            {
                props.setPage(1)
            }
        }, 500); // 500ms debounce time

        // Clear the timeout if props.selectedOptions changes before 500ms
        return () => {
            clearTimeout(handler);
        };
    }, [props.selectedOptions, searchTerm]); // Only rerun if props.selectedOptions changes

    function extractNumbers(rangeStr) {
        console.log(rangeStr)
        // Remove any non-digit characters, except for commas and spaces
        const cleanStr = rangeStr.replace(/[^0-9,-\s]/g, '');
      
        // Split the cleaned string by dash and remove any spaces
        const [num1, num2] = cleanStr.split('-').map(num => parseInt(num.replace(/,/g, '').trim()));
      
        return [num1, num2];
    }

    const searchDeals = (input, filters, page) => {
        console.log(filters)
        console.log('wwwwwwwwwww')
        var myHeaders = new Headers();
        myHeaders.append("Accept", "application/json");
        myHeaders.append("Authorization", "Bearer " + localStorage.getItem('access_token'));
        myHeaders.append("Content-Type", "application/json");

        let tempFilters = {...filters}
        let tempPage = page !== undefined ? page:props.page

        tempFilters = {
            ...tempFilters,
            brand: tempFilters?.make,
            price_min: (tempFilters.price === undefined || tempFilters.price === '') ? null:extractNumbers(tempFilters?.price)[0],
            price_max: (tempFilters.price === undefined || tempFilters.price === '') ? null:extractNumbers(tempFilters?.price)[1],
            mpg_min: (tempFilters.mpg === undefined || tempFilters.mpg === '') ? null:extractNumbers(tempFilters?.mpg)[0],
            mpg_max: (tempFilters.mpg === undefined || tempFilters.mpg === '') ? null:extractNumbers(tempFilters?.mpg)[1],
            discounted_percentage_min: (tempFilters.discount === undefined || tempFilters.discount === '') ? null:(extractNumbers(tempFilters?.discount)[0])/100,
            discounted_percentage_max: (tempFilters.discount === undefined || tempFilters.discount === '') ? null:(extractNumbers(tempFilters?.discount)[1])/100,
            model: tempFilters?.model,
            vehicle_type: tempFilters?.bodyType,
            fuel: tempFilters?.fuelType,
            total_seats: tempFilters?.seats?.[0],
            total_doors: tempFilters?.doors?.[0],
        }

        delete tempFilters.make
        delete tempFilters.price
        delete tempFilters.bodyType
        delete tempFilters.fuelType
        delete tempFilters.mpg
        delete tempFilters.seats
        delete tempFilters.doors
        delete tempFilters.discount

        console.log(tempFilters)
    
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            redirect: 'follow',
            body: JSON.stringify({
                search_field: input,
                ...tempFilters,
            })
        }

        window.apiRequest('/car/search?limit=12&skip=' + ((tempPage-1)*12), requestOptions, (result, status) => {   
            console.log(result)
            if (status === 200)
            {
                setTotalPages(Math.ceil((result.total_cars/12)))
                setCars(result)      
            } else {}
        })
    }

    const [totalPages, setTotalPages] = useState(1)

    const handlePageChange = (change) => {
        if (props.page + change < 1)
        {
            props.setPage(1)
        }
        else
        {
            if (props.page + change > totalPages)
            {
                props.setPage(totalPages)
            }
            else
            {
                props.setPage((page) => page + change)
            }
        }
    }

    const handleSearchTermChange = (value) => {
        setSearchTerm(value)
    }

    return (
        <div style={{ display: 'flex', flexDirection: 'column', fontFamily: 'inter', color: window.colors.primary, ...props.style }}>

            <div style={{border: '1px solid ' + window.colors.border, display: 'flex', flexDirection: 'row', width: window.innerWidth < 1550 ? '95%':'100%', alignItems: 'center', height: '46px', borderRadius: '8px'}}>
                <img src={search} style={{marginLeft: '15px'}}></img>
                <input placeholder='Search Make, Model, or Keyword' value={searchTerm} onChange={(e) => handleSearchTermChange(e.target.value)} style={{border: 'none', fontSize: '14px', flexGrow: 1, marginLeft: '20px', fontWeight: '500', fontFamily: 'inter',outline: 'none'}}></input>
                <motion.button whileHover={window.bAnim} style={{backgroundColor: window.colors.primary, marginLeft: 'auto', padding: '10px', marginRight: '5px', borderRadius: '5px', cursor: 'pointer', border: 'none', zIndex: 1}}>
                    <span style={{color: window.colors.white}}>GO</span>
                </motion.button>
            </div>
        
            <div style={{width: window.innerWidth < 1550 ? 'calc(100% - 20px)':('auto'), display: 'flex', flexDirection: 'column', backgroundColor: window.colors.primary, padding: '10px', marginTop: '0px', borderRadius: '8px', marginTop: '30px'}}>
                <div style={{display: 'flex', flexDirection: 'row'}}>
                    <div style={{display: 'flex', flexDirection: 'row'}}>
                        <span style={{color: window.colors.white}}>Filters</span>
                        <button onClick={() => props.setSelectedOptions([])} style={{marginLeft: '10px', border: 0, color: window.colors.primary, fontWeight: '500', fontFamily: 'inter', borderRadius: 5, cursor: 'pointer'}}>Clear</button>
                    </div>
                    {window.innerWidth < 800 && <button onClick={() => props.setShowFilters(!props.showFilters)} style={{marginLeft: 'auto', backgroundColor: window.colors.white, color: window.colors.primary, border: 'none', fontWeight: '600', fontFamily: 'inter', padding: '5px 10px 5px 10px', borderRadius: '3px', cursor: 'pointer'}}>{props.showFilters ? 'Close Filters':'Open Filters'}</button>}
                </div>
                <div style={{display: 'flex', flexDirection: 'row', flexWrap: 'wrap', minHeight: props.selectedOptions.length > 0 && '45px', minHeight: '41px'}}>
                    {Object.entries(props.selectedOptions).map(([key, value]) => {
                        return (
                            <div style={{display: 'flex', flexDirection: 'row', backgroundColor: window.colors.white, alignItems: 'center', borderRadius: '3px', padding: '3px 10px 3px 10px', marginRight: '20px', marginTop: '15px', alignItems: 'center' }}>
                                <span>{value}</span>
                                <img src={x} onClick={() => deleteFilter(key)} style={{marginLeft: 'auto', width: '10px', height: '10px', marginLeft: '10px', marginTop: '1px', cursor: 'pointer'}}></img>
                            </div>
                        )
                    })}
                </div>
            </div>

            <div style={getGridStyle()}>
                {cars?.cars?.map((car, index) => {
                    return (
                        <Link to={'/vehicle?id=' + car.id} style={{textDecoration: 'none', color: window.colors.primary}}>
                            <motion.div whileHover={{boxShadow: '0px 0px 10px 1px rgba(0,0,0,0.2)'}} style={{display: 'flex', flexDirection: 'column', border: '0px solid ' + window.colors.border, borderRadius: '5px', cursor: 'pointer', height: 'auto', boxShadow: '0px 0px 10px 1px rgba(0,0,0,0.05)'}}>
                                <img src={'https://cdn.autovitus.com/cars/' + car.photos[0]} style={{height: '218px', objectFit: 'cover', borderBottom: '1px solid ' + window.colors.border, borderTopLeftRadius: '5px', borderTopRightRadius: '5px', width: '100%'}}></img>
                                <div style={{display: 'flex', flexDirection: 'row', padding: '20px', maxWidth: '100%', height: '100%'}}>
                                    <div style={{display: 'flex', flexDirection: 'column'}}>
                                        <span style={{fontWeight: '700', fontSize: '20px'}}>{car.year} {car.brand} {car.model}</span>
                                        <span style={{fontWeight: '500'}}>{car.trim}</span>
                                    </div>
                                </div>
                                <div style={{display: 'flex', flexDirection: 'row', padding: '20px', paddingTop: '0px'}}>
                                    <div style={{marginTop: 'auto'}}>
                                        <span style={{fontSize: '20px', fontWeight: '600'}}>Lease</span>
                                    </div>
                                    <div style={{display: 'flex', flexDirection: 'column', textAlign: 'right', marginLeft: 'auto', marginTop: 'auto'}}>
                                        <span style={{color: window.colors.muted, textDecoration: 'line-through', fontSize: '20px', fontWeight: '700'}}>${window.addCommas(car.msrp)}</span>
                                        <span style={{fontWeight: '700', fontSize: '26px'}}>${window.addCommas(car.discounted_msrp)}</span>
                                    </div>
                                </div>
                                <div style={{display: 'flex', flexDirection: 'row', paddingTop: '15px', paddingBottom: '15px', backgroundColor: window.colors.white, borderBottomLeftRadius: '5px', borderBottomRightRadius: '5px', borderTop: '1px solid ' + window.colors.border}}>
                                    <div style={{display: 'flex', flexDirection: 'column', borderRight: '1px solid ' + window.colors.border, flexGrow: 1, alignItems: 'center'}}>
                                        <span style={{color: window.colors.primary, fontWeight: '500'}}>${car.lease_monthly?.toFixed(0)}</span>
                                        <span style={{color: window.colors.muted}}>/month</span>
                                    </div>
                                    <div style={{display: 'flex', flexDirection: 'column', flexGrow: 1, alignItems: 'center'}}>
                                        <span style={{color: window.colors.primary, fontWeight: '500'}}>{car.lease_term_length}</span>
                                        <span style={{color: window.colors.muted}}>months</span>
                                    </div>
                                    <div style={{display: 'flex', flexDirection: 'column', borderLeft: '1px solid ' + window.colors.border, flexGrow: 1, alignItems: 'center'}}>
                                        <span style={{color: window.colors.primary, fontWeight: '500'}}>${car.lease_down}</span>
                                        <span style={{color: window.colors.muted}}>down</span>
                                    </div>
                                </div>
                            </motion.div>
                        </Link>
                    )
                })}
            </div>

            <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', margin: 'auto', marginTop: '30px'}}>
                <img onClick={() => handlePageChange(-1)} src={sideArrow} style={{marginRight: '18px', cursor: 'pointer', transform: 'rotateY(180deg)'}}></img>
                {new Array(totalPages).fill('').map((item, index) => {
                    return (
                        <div className='center' onClick={() => props.setPage(index + 1)} style={{border: index !== (props.page-1) ? '':'2px solid ' + window.colors.primary, borderRadius: '100px', width: '30px', height: '30px', marginRight: '10px', cursor: 'pointer'}}>
                            <span style={{fontWeight: '600', fontSize: '16px', marginLeft: '-1px'}}>{index + 1}</span>
                        </div>
                    )
                })}
                <img onClick={() => handlePageChange(1)} src={sideArrow} style={{marginLeft: '5px', cursor: 'pointer'}}></img>
            </div>

        </div>
    );
};

export default Main;
