import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from "framer-motion";
import { Link } from "react-router-dom";

import P1 from '../media/P1.png'
import P2 from '../media/P2.png'
import P3 from '../media/P3.png'
import P4 from '../media/P4.png'

const Main = (props) => {

    const titles = ['Confirmation of Agreement by AutoVitus Representative', 'Credit Evaluation and Financing Arrangements', 'Remote Documentation and Final Paperwork', 'Vehicle Delivery']
    const descriptions = [
        'Once you`ve expressed interest in the vehicle, an AutoVitus representative will reach out to confirm your agreement and provide a thorough overview of the next steps. This includes detailing timelines, ensuring all your preferences are noted, and answering any questions you might have about the upcoming process.',
        'The dealership responsible for fulfilling your vehicle request will conduct a credit check to finalize financing options. This step helps secure a loan that aligns with your financial profile, ensuring that you receive the best possible interest rate and terms to suit your budget and circumstances.',
        'To make the car-buying experience seamless, the dealership will prepare and send all the necessary documents for remote completion. This includes the sales contract, loan agreements, and registration details, allowing you to conveniently finalize the purchase without needing to visit the dealership in person.',
        'Upon finalizing all paperwork, the dealership will arrange for the vehicle to be delivered directly to your home. This step completes the car purchase process, providing you with the convenience of having your new car delivered, fully ready for you to start enjoying immediately.',
    ]
    const images = [P1,P2,P3,P4]

    return (
        <div style={{ display: 'flex', flexDirection: 'column', height: 'auto', fontFamily: 'inter', color: window.colors.primary, ...props.style }}>
            <span style={{fontSize: window.innerWidth < 600 ? '58px':'96px', fontWeight: '700', textAlign: 'center', marginTop: '40px', marginBottom: '40px'}}>The Process</span>
            <div style={{display: 'flex', flexDirection: 'column'}}>
                {titles.map((title, index) => {
                    return (
                        <div style={{display: 'flex', flexDirection: index % 2 ? (window.innerWidth < 750 ? 'column':'row-reverse'):(window.innerWidth < 750 ? 'column':'row'), height: window.innerWidth > 750 && '500px', alignItems: window.innerWidth < 750 && 'center', textAlign: window.innerWidth < 750 && 'center'}}>
                            <div style={{display: 'flex', flexDirection: 'column', width: window.innerWidth < 750 ? '80%':'50%', justifyContent: 'center'}}>
                                <span style={{ fontSize: window.innerWidth < 600 ? '32px':'40px', fontWeight: '700', marginLeft: window.innerWidth > 750 && '100px' }}>{title}</span>
                                <span style={{ fontSize: '20px', color: window.colors.muted, marginTop: '20px', lineHeight: '130%', marginLeft: window.innerWidth > 750 && '100px', fontWeight: '300'}}>{descriptions[index]}</span>
                            </div>
                            {window.innerWidth > 1360 && <div style={{display: 'flex', flexDirection: 'column', width: '6px', backgroundColor: window.colors.primary, alignItems: 'center', justifyContent: 'center', borderRadius: '100px', marginLeft: '20px', marginRight: '20px'}}>
                                <div className='center' style={{backgroundColor: window.colors.primary, width: '40px', height: '40px', borderRadius: '100px'}}>
                                    <span style={{color: window.colors.white, fontWeight: '500', fontSize: '24px', marginRight: '2px'}}>{index + 1}</span>
                                </div>
                            </div>}
                            <div style={{display: 'flex', flexDirection: 'column', width: '50%', justifyContent: 'center', alignContent: 'center', marginLeft: window.innerWidth < 1360 && (window.innerWidth < 750 ? '0px':'40px'), marginBottom: window.innerWidth < 750 && '75px', marginTop: window.innerWidth < 750 && '20px'}}>
                                <img src={images[index]} style={{margin: 'auto', width: '100%', maxWidth: '450px'}}></img>
                            </div>
                        </div>
                    )
                })}

            </div>
        </div>
    );
    
}

export default Main;
