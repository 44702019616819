import React, { useState, useEffect, useRef, useCallback } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { useLocation } from 'react-router-dom';

import featuredCar from '../media/featuredCar.png';
import downArrow from '../media/down-arrow.png';

import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';

const Main = (props) => {
    const [openIndex, setOpenIndex] = useState(null);

    const [searchFields, setSearchFields] = useState([
        { name: 'Price', key: 'price', options: [], needSearch: false, },
        { name: 'Discount', key: 'discount', options: [], needSearch: false, },
        { name: 'Make', key: 'make', options: [], needSearch: true, },
        { name: 'Model', key: 'model', options: [], needSearch: true, },
        { name: 'Trim', key: 'trim', options: [], needSearch: true, },
        { name: 'Body Type', key: 'bodyType', options: ['Sedan', 'SUV', 'Truck', 'Coupe', 'Convertible'], needSearch: false, },
        { name: 'Fuel Type', key: 'fuelType', options: ['Gas', 'Electric', 'Hybrid', 'Diesal'], needSearch: false, },
        { name: 'MPG', key: 'mpg', options: [], needSearch: false, },
        { name: 'Transmission', key: 'transmission', options: ['Automatic', 'Manual'], needSearch: false, },
        { name: 'Seats', key: 'seats', options: ['2 Seats', '3 Seats', '4 Seats', '5 Seats', '6 Seats', '7 Seats'], needSearch: false, },
        { name: 'Doors', key: 'doors', options: ['2 Doors', '3 Doors', '4 Doors', '5 Doors'], needSearch: false, },
        { name: 'Drive Train', key: 'driveTrain', options: ['AWD', 'RWD', 'FWD', '4WD'], needSearch: false, },
    ]);

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);

    useEffect(() => {
        if (queryParams.get('filter') !== null)
        {
            toggleOption(queryParams.get('field'), queryParams.get('filter'))
        }      
    },[])

    const updateOptionsForKey = (key, newOptions) => {
        setSearchFields(prevFields => 
            prevFields.map(field => 
                field.key === key ? { ...field, options: newOptions } : field
            )
        );
    };

    const toggleDropdown = (index) => {
        setOpenIndex(openIndex === index ? null : index);
    };

    const exclusionOptions = ['make', 'model', 'trim', 'price', 'mpg', 'discount','bodyType','fuelType','transmission','seats','doors','driveTrain']

    const useDebouncedCallback = (callback, delay) => {
        const timer = useRef(null);
    
        return useCallback((...args) => {
            if (timer.current) clearTimeout(timer.current);
    
            timer.current = setTimeout(() => {
                callback(...args);
                timer.current = null;
            }, delay);
        }, [callback, delay]);
    };
    
    const rangeOption = useDebouncedCallback((key, minVal, maxVal) => {
        console.log(key, minVal, maxVal);

        let prefix = '';
        let suffix = '';
        if (key === 'price') {
            prefix = '$';
        } else if (key === 'discount') {
            suffix = '%';
        } else if (key === 'mpg') {
            suffix = 'MPG';
        }

        const formattedValue = prefix + window.addCommas(minVal) + suffix + ' - ' + prefix + window.addCommas(maxVal) + suffix;
        toggleOption(key, formattedValue);
    }, 500);

    const toggleOption = (fieldKey, option) => {
        console.log(fieldKey, option)
        if (fieldKey === 'make' || fieldKey === 'model' || fieldKey === 'trim')
        {
            setSearchQuery('')
        }
    
        if (fieldKey === 'make') {
            getModels(option);
        } else if (fieldKey === 'model') {
            getTrims(props.selectedOptions?.make, option);
        }
    
        props.setSelectedOptions((prev) => {
            let updatedOptions = { ...prev };
            
            // Handle clearing dependent fields
            if (fieldKey === 'make') {
                // Clear both 'model' and 'trim' when 'make' changes
                delete updatedOptions['model'];
                delete updatedOptions['trim'];
            } else if (fieldKey === 'model') {
                // Clear 'trim' when 'model' changes
                delete updatedOptions['trim'];
            }
    
            const fieldOptions = updatedOptions[fieldKey] || [];
            if (fieldOptions.includes(option)) {
                // Remove the option if already selected
                console.log('removing')
                delete updatedOptions[fieldKey]
            } else {
                // Add the option if not selected
                console.log('adding')
                updatedOptions[fieldKey] = exclusionOptions.includes(fieldKey) 
                    ? option
                    : [...fieldOptions, option];
            }

            console.log({...updatedOptions})
    
            return updatedOptions;
        });
    };    

    const resolveOpacity = (curWidth, curFilter) => {
        if (curWidth < 800) {
            if (curFilter) {
                return 'relative';
            } else {
                return 'inline';
            }
        } else {
            return 'inline';
        }
    };

    const [priceRange, setPriceRange] = useState([40000, 100000]); 
    const [discountRange, setDiscountRange] = useState([5, 25]); 
    const [MPGRange, setMPGRange] = useState([25, 50]); 

    const handlePriceSliderChange = (newRange) => {
        setPriceRange(newRange);
        rangeOption('price',newRange[0],newRange[1])
    };

    const handleDiscountSliderChange = (newRange) => {
        setDiscountRange(newRange);
        rangeOption('discount',newRange[0],newRange[1])
    };

    const handleMPGSliderChange = (newRange) => {
        setMPGRange(newRange);
        rangeOption('mpg',newRange[0],newRange[1])
    };

    const [minPrice, setMinPrice] = useState(0)
    const [maxPrice, setMaxPrice] = useState(100)

    useEffect(() => {
        getFilterOptions()
        getHighLowPrice()
    },[])

    const getFilterOptions = () => {
        var myHeaders = new Headers();
        myHeaders.append("Accept", "application/json");
        myHeaders.append("Content-Type", "application/json");
    
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            redirect: 'follow',
            body: JSON.stringify({})
        }

        window.apiRequest('/car_info/brands', requestOptions, (result, status) => {   
            if (status === 200)
            {
                updateOptionsForKey('make', result)
            } else {}
        })
    }

    const getHighLowPrice = () => {
        var myHeaders = new Headers();
        myHeaders.append("Accept", "application/json");
        myHeaders.append("Content-Type", "application/json");
    
        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow',
        }

        window.apiRequest('/car/extremes', requestOptions, (result, status) => {   
            if (status === 200)
            {
                setMinPrice(Math.floor(result.min / 5000) * 5000)
                setMaxPrice(Math.ceil(result.max / 5000) * 5000)
            } else {}
        })
    }

    const getModels = (make) => {
        var myHeaders = new Headers();
        myHeaders.append("Accept", "application/json");
        myHeaders.append("Content-Type", "application/json");
    
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            redirect: 'follow',
            body: JSON.stringify({
                brand: make
            })
        }

        window.apiRequest('/car_info/models', requestOptions, (result, status) => {   
            if (status === 200)
            {
                updateOptionsForKey('model', result)
            } else {}
        })
    }

    const getTrims = (make, model) => {
        console.log(make, model)
        var myHeaders = new Headers();
        myHeaders.append("Accept", "application/json");
        myHeaders.append("Content-Type", "application/json");
    
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            redirect: 'follow',
            body: JSON.stringify({
                brand: make,
                model: model,
            })
        }

        window.apiRequest('/car_info/trims', requestOptions, (result, status) => {   
            console.log(result)
            if (status === 200)
            {
                updateOptionsForKey('trim', result)
            } else {}
        })
    }

    const [searchQuery, setSearchQuery] = useState('');

    const priceInput = () => {
        return <div style={{width: '100%', height: 'auto'}}>
            <div style={{display: 'flex', flexDirection: 'row', width: '100%', marginBottom: '5px', marginTop: '25px'}}>
                <span style={{fontSize: '24px', fontWeight: '700'}}>Price Range</span>
            </div>
            <span style={{color: window.colors.muted}}>Discounted MSRP</span>
            <div style={{display: 'flex', flexDirection: 'row', width: '100%', marginBottom: '30px', marginTop: '20px'}}>
                <input type='text' style={{fontWeight: '500', width: '40%', textAlign: 'center', fontSize: '20px', border: '2px solid ' + window.colors.border, borderRadius: '3px'}} value={'$' + window.addCommas(priceRange[0])}></input>
                <span type='text' style={{fontWeight: '800', width: '20%', textAlign: 'center', fontSize: '20px'}}>-</span>
                <input type='text' style={{fontWeight: '500', width: '40%', textAlign: 'center', fontSize: '20px', border: '2px solid ' + window.colors.border, borderRadius: '3px', padding: '5px'}} value={'$' + window.addCommas(priceRange[1])}></input>
            </div>
            <Slider range min={minPrice} max={maxPrice} step={5000} value={priceRange} onChange={handlePriceSliderChange} trackStyle={[{backgroundColor: window.colors.primary, height: '10px'}]} handleStyle={[{backgroundColor: window.colors.primary, border: '0px', boxShadow: 'none', height: '20px', width: '20px'}, {backgroundColor: window.colors.primary, border: '0px', boxShadow: 'none', height: '20px', width: '20px'}]} activeDotStyle={{ backgroundColor: window.colors.primary }} railStyle={{height: '10px'}}></Slider>
            <div style={{paddingBottom: '40px'}}></div>
        </div>
    }

    const discountInput = () => {
        return <div style={{width: '100%', height: 'auto'}}>
            <div style={{display: 'flex', flexDirection: 'row', width: '100%', marginBottom: '5px', marginTop: '25px'}}>
                <span style={{fontSize: '24px', fontWeight: '700'}}>Discount Range</span>
            </div>
            <span style={{color: window.colors.muted}}>The amount of the MSRP</span>
            <div style={{display: 'flex', flexDirection: 'row', width: '100%', marginBottom: '30px', marginTop: '20px'}}>
                <input type='text' style={{fontWeight: '500', width: '40%', textAlign: 'center', fontSize: '20px', border: '2px solid ' + window.colors.border, borderRadius: '3px'}} value={window.addCommas(discountRange[0]) + '%'}></input>
                <span type='text' style={{fontWeight: '800', width: '20%', textAlign: 'center', fontSize: '20px'}}>-</span>
                <input type='text' style={{fontWeight: '500', width: '40%', textAlign: 'center', fontSize: '20px', border: '2px solid ' + window.colors.border, borderRadius: '3px', padding: '5px'}} value={window.addCommas(discountRange[1]) + '%'}></input>
            </div>
            <Slider range min={0} max={60} step={1} value={discountRange} onChange={handleDiscountSliderChange} trackStyle={[{backgroundColor: window.colors.primary, height: '10px'}]} handleStyle={[{backgroundColor: window.colors.primary, border: '0px', boxShadow: 'none', height: '20px', width: '20px'}, {backgroundColor: window.colors.primary, border: '0px', boxShadow: 'none', height: '20px', width: '20px'}]} activeDotStyle={{ backgroundColor: window.colors.primary }} railStyle={{height: '10px'}}></Slider>
            <div style={{paddingBottom: '40px'}}></div>
        </div>
    }

    const MPGInput = () => {
        return <div style={{width: '100%', height: 'auto'}}>
            <div style={{display: 'flex', flexDirection: 'row', width: '100%', marginBottom: '5px', marginTop: '25px'}}>
                <span style={{fontSize: '24px', fontWeight: '700'}}>MPG Range</span>
            </div>
            <span style={{color: window.colors.muted}}>The average MPG of both City/Highway</span>
            <div style={{display: 'flex', flexDirection: 'row', width: '100%', marginBottom: '30px', marginTop: '20px'}}>
                <input type='text' style={{fontWeight: '500', width: '40%', textAlign: 'center', fontSize: '20px', border: '2px solid ' + window.colors.border, borderRadius: '3px'}} value={window.addCommas(MPGRange[0])}></input>
                <span type='text' style={{fontWeight: '800', width: '20%', textAlign: 'center', fontSize: '20px'}}>-</span>
                <input type='text' style={{fontWeight: '500', width: '40%', textAlign: 'center', fontSize: '20px', border: '2px solid ' + window.colors.border, borderRadius: '3px', padding: '5px'}} value={window.addCommas(MPGRange[1])}></input>
            </div>
            <Slider range min={10} max={60} step={1} value={MPGRange} onChange={handleMPGSliderChange} trackStyle={[{backgroundColor: window.colors.primary, height: '10px'}]} handleStyle={[{backgroundColor: window.colors.primary, border: '0px', boxShadow: 'none', height: '20px', width: '20px'}, {backgroundColor: window.colors.primary, border: '0px', boxShadow: 'none', height: '20px', width: '20px'}]} activeDotStyle={{ backgroundColor: window.colors.primary }} railStyle={{height: '10px'}}></Slider>
            <div style={{paddingBottom: '40px'}}></div>
        </div>
    }

    const handleShowingFilters = (width, isVisible) => {
        if (width > 800)
        {
            return true;
        }
        else
        {
            if (isVisible)
            {
                return true
            }
            else
            {
                return false;
            }
        }
    }

    return (<>
        {handleShowingFilters(window.innerWidth, props.showFilters) && (<motion.div className='noSelect' style={{ display: 'flex', flexDirection: 'column', ...props.style, fontFamily: 'inter', color: window.colors.primary, position: window.innerWidth < 800 && 'absolute', backgroundColor: window.colors.white, translateY: window.innerWidth < 800 && '195px'}}>

            {searchFields.map((field, index) => (
                <div key={index}>
                    <div onClick={() => toggleDropdown(index)} style={{ border: '1px solid ' + window.colors.border, width: '275px', display: 'flex', flexDirection: 'row', alignItems: 'center', padding: '20px', borderTop: index === 0 ? '1px solid ' + window.colors.border : 'none', borderTopLeftRadius: index === 0 ? '10px' : '0px', borderTopRightRadius: index === 0 ? '10px' : '0px', borderBottomLeftRadius: index === searchFields.length - 1 && openIndex !== index ? '10px' : '0px', borderBottomRightRadius: index === searchFields.length - 1 && openIndex !== index ? '10px' : '0px', cursor: 'pointer' }}>
                        <span style={{ marginRight: 'auto' }}>{field.name}</span>
                        <img src={downArrow} style={{ width: '13px', height: '13px', transform: openIndex === index ? '':'rotate(180deg)' }} alt={`${field.name} arrow`} />
                    </div>
                    <AnimatePresence initial={false}>
                        {openIndex === index && (
                            <motion.div initial={{ height: 0, opacity: 0 }} animate={{ height: 'auto', opacity: 1 }} exit={{ height: 0, opacity: 0 }} style={{ overflow: 'hidden', border: '1px solid ' + window.colors.border, borderTop: 'none', width: '275px', padding: '0px 20px 10px 20px', borderBottomLeftRadius: index === searchFields.length - 1 ? '10px' : '0px', borderBottomRightRadius: index === searchFields.length - 1 ? '10px' : '0px' }}>
                                
                                {/* Add search input */}
                                {field.needSearch && <div>
                                    <div style={{marginTop: '10px'}}>
                                        <span>Search</span>
                                    </div>
                                    <input type="text" placeholder="Search options..." value={searchQuery} onChange={(e) => setSearchQuery(e.target.value)} style={{ width: '95%', padding: '5px', border: '1px solid ' + window.colors.border, borderRadius: '3px', marginTop: '10px', height: '30px', fontWeight: '500', color: window.colors.primary, borderRadius: '5px', fontFamily: 'inter'}} />
                                </div>}

                                {/* Filtered options */}
                                <div className='scrollbar' style={{maxHeight: '300px', overflowY: 'auto', marginTop: '10px'}}>
                                    {(index !== 0 && index !== 1 && index !== 7) && field.options.filter(option => option.toLowerCase().includes(searchQuery.toLowerCase())).map((option, optIndex) => (
                                        <div key={optIndex} onClick={() => toggleOption(field.key, option)} style={{ padding: '10px 0', borderBottom: optIndex !== field.options.length - 1 ? '1px solid ' + window.colors.border : 'none', cursor: 'pointer', display: 'flex', flexDirection: 'row', marginTop: index > 0 && '10px', marginBottom: '10px' }}>
                                            <div style={{ width: '16px', height: '16px', border: '1px solid ' + window.colors.primary, borderRadius: '3px', backgroundColor: (props.selectedOptions[field.key] || []).includes(option) ? window.colors.primary : 'transparent' }}></div>
                                            <span style={{ marginLeft: '10px' }}>{option}</span>
                                        </div>
                                    ))}
                                </div>

                                {index === 0 && priceInput()}
                                {index === 1 && discountInput()}
                                {index === 7 && MPGInput()}
                            </motion.div>
                        )}
                    </AnimatePresence>
                </div>
            ))}
        </motion.div>)}
        </>
    );
};

export default Main;
