import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from "framer-motion";
 
import leaseShareImage from '../media/leaseShareImage.png'
import { Link } from "react-router-dom";

const Main = (props) => {

    return (
        <div style={{display: 'flex', flexDirection: window.innerWidth < 1050 ? 'column':'row', width: window.innerWidth < 1000 ? '70%':'90%', height: 'auto', fontFamily: 'inter', color: window.colors.primary, background: window.innerWidth > 1050 && 'linear-gradient(45deg, ' + window.colors.primary + ' 50%, #FFFFFF 50%)', borderRadius: '20px', padding: '20px 40px 20px 40px', border: '0px solid ' + window.colors.border, ...props.style, marginLeft: 'auto', marginRight: 'auto', boxShadow: '0px 0px 10px 1px rgba(0,0,0,0.1)'}}>
            <div style={{display: 'flex', flexDirection: 'column', width: window.innerWidth < 1050 ? '100%':'50%', alignItems: window.innerWidth < 1050 && 'center', textAlign: window.innerWidth < 1050 && 'center'}}>
                <span style={{color: window.innerWidth < 1050 ? window.colors.primary:window.colors.white, fontSize: window.innerWidth < 850 ? (window.innerWidth < 575 ? '20px':'32px'):'48px', fontWeight: '700', width: window.innerWidth < 1050 ? '100%':'80%', lineHeight: '95%'}}>Share your lease deal and we'll secure a better offer for you.</span>
                <Link to='/share-lease' style={{width: '150px'}}><motion.button whileHover={{width: '170px'}} style={{backgroundColor: window.innerWidth < 1050 ? window.colors.primary:window.colors.white, color: window.innerWidth < 1050 ? window.colors.white:window.colors.primary, border: 'none', width: '150px', padding: '10px', borderRadius: '5px', marginTop: '20px', fontWeight: '600', fontSize: '14px', cursor: 'pointer'}}>Share</motion.button></Link>
            </div>
            <img src={leaseShareImage} style={{marginLeft: 'auto', maxWidth: '347px', width: '100%', height: 'auto', marginTop: '50px', marginRight: window.innerWidth < 1050 && 'auto', maxHeight: '147px'}}></img>
        </div>
    );
}

export default Main;
