import React, { useState, useEffect } from 'react';
import { motion } from "framer-motion";
import { Link } from "react-router-dom";

import featuredCar from '../media/featuredCar.png';

const Main = (props) => {
    const [gridTemplate, setGridTemplate] = useState('1fr 1fr 1fr 1fr');

    useEffect(() => {
        const updateGrid = () => {
            if (window.innerWidth < 800) {
                setGridTemplate('1fr');
            } else if (window.innerWidth < 1550) {
                setGridTemplate('1fr 1fr');
            } else {
                setGridTemplate('1fr 1fr 1fr 1fr');
            }
        };
        window.addEventListener('resize', updateGrid);
        updateGrid();
        return () => window.removeEventListener('resize', updateGrid);
    }, []);

    const [featuredCars, setFeaturedCars] = useState([])

    useEffect(() => {
        var myHeaders = new Headers();
        myHeaders.append("Accept", "application/json");
        myHeaders.append("Authorization", "Bearer " + localStorage.getItem('access_token'));
        myHeaders.append("Content-Type", "application/json");
    
        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow',
        }

        window.apiRequest('/car/featured', requestOptions, (result, status) => {   
            console.log(result)
            if (status === 200)
            {
                setFeaturedCars(result)
            } else {}
        })
    },[])

    const calculateLease = (car) => {
        const dpAmount = 0.05
        return window.addCommas((((car.discounted_msrp - car.residual) - (car.discounted_msrp * dpAmount))/car.lease_term_length).toFixed(0))
    }

    const calculateFinance = (car) => {
        const dpAmount = 0.1
        return window.addCommas((((car.discounted_msrp) - (car.discounted_msrp * dpAmount))/car.finance_term_length).toFixed(0))
    }

    return (
        <div style={{display: 'flex', flexDirection: 'column', fontFamily: 'inter', color: window.colors.primary, ...props.style, padding: window.innerWidth < 475 && '15px'}}>
            <div style={{display: 'flex', flexDirection: 'row', margin: window.innerWidth < 1550 && 'auto'}}>
                <span style={{fontSize: '32px', fontWeight: '700', letterSpacing: '-1px'}}>Featured Vehicles</span>
                <Link to='/shop'><motion.button whileHover={window.bAnim} style={{marginLeft: '15px', backgroundColor: 'transparent', border: 'none', outline: 'none', color: window.colors.link, marginTop: '10px', fontSize: '16px', fontWeight: '500', fontFamily: 'inter', cursor: 'pointer'}}>View All Deals</motion.button></Link>
            </div>
            <div style={{display: 'grid', gridTemplateColumns: gridTemplate, gap: '20px', marginLeft: window.innerWidth < 1550 && 'auto', marginRight: window.innerWidth < 1550 && 'auto', marginTop: '50px'}}>
                {featuredCars.map((car, index) => {
                    return (
                        <Link to={'/vehicle?id=' + car.id} style={{textDecoration: 'none', color: window.colors.primary}}>
                            <motion.div whileHover={{boxShadow: '0px 0px 10px 1px rgba(0,0,0,0.2)'}} style={{display: 'flex', flexDirection: 'column', border: '0px solid ' + window.colors.border, borderRadius: '5px', cursor: 'pointer', height: 'auto', boxShadow: '0px 0px 10px 1px rgba(0,0,0,0.05)'}}>
                                <img src={'https://cdn.autovitus.com/cars/' + car.photos[0]} style={{height: '218px', objectFit: 'cover', borderBottom: '1px solid ' + window.colors.border, borderTopLeftRadius: '5px', borderTopRightRadius: '5px', width: '100%'}}></img>
                                <div style={{display: 'flex', flexDirection: 'row', padding: '20px', maxWidth: '100%', height: '100%'}}>
                                    <div style={{display: 'flex', flexDirection: 'column'}}>
                                        <span style={{fontWeight: '700', fontSize: '20px'}}>{car.year} {car.brand} {car.model}</span>
                                        <span style={{fontWeight: '500'}}>{car.trim}</span>
                                    </div>
                                </div>
                                <div style={{display: 'flex', flexDirection: 'row', padding: '20px', paddingTop: '0px'}}>
                                    <div style={{marginTop: 'auto'}}>
                                        <span style={{fontSize: '20px', fontWeight: '600'}}>Lease</span>
                                    </div>
                                    <div style={{display: 'flex', flexDirection: 'column', textAlign: 'right', marginLeft: 'auto', marginTop: 'auto'}}>
                                        <span style={{color: window.colors.muted, textDecoration: 'line-through', fontSize: '20px', fontWeight: '700'}}>${window.addCommas(car.msrp)}</span>
                                        <span style={{fontWeight: '700', fontSize: '26px'}}>${window.addCommas(car.discounted_msrp)}</span>
                                    </div>
                                </div>
                                <div style={{display: 'flex', flexDirection: 'row', paddingTop: '15px', paddingBottom: '15px', backgroundColor: window.colors.white, borderBottomLeftRadius: '5px', borderBottomRightRadius: '5px', borderTop: '1px solid ' + window.colors.border}}>
                                    <div style={{display: 'flex', flexDirection: 'column', borderRight: '1px solid ' + window.colors.border, flexGrow: 1, alignItems: 'center'}}>
                                        <span style={{color: window.colors.primary, fontWeight: '500'}}>${car.lease_monthly}</span>
                                        <span style={{color: window.colors.muted}}>/month</span>
                                    </div>
                                    <div style={{display: 'flex', flexDirection: 'column', flexGrow: 1, alignItems: 'center'}}>
                                        <span style={{color: window.colors.primary, fontWeight: '500'}}>{car.lease_term_length}</span>
                                        <span style={{color: window.colors.muted}}>months</span>
                                    </div>
                                    <div style={{display: 'flex', flexDirection: 'column', borderLeft: '1px solid ' + window.colors.border, flexGrow: 1, alignItems: 'center'}}>
                                        <span style={{color: window.colors.primary, fontWeight: '500'}}>${car.lease_down}</span>
                                        <span style={{color: window.colors.muted}}>down</span>
                                    </div>
                                </div>
                            </motion.div>
                        </Link>
                    );
                })}
            </div>
        </div>
    );
}

export default Main;
