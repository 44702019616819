import React, { useState, useEffect } from 'react'
import { motion, AnimatePresence } from 'framer-motion'

import Customers from '../media/Customers.png'
import Arrow from '../media/Arrow.png'

const Main = (props) => {
    
    const [email, setEmail] = useState('')
    const [response, setResponse] = useState('')
    const [showResponse, setShowResponse] = useState(false)

    const subscribe = (email) => {
        var myHeaders = new Headers()
        myHeaders.append("Accept", "application/json")
        myHeaders.append("Content-Type", "application/json")

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            redirect: 'follow',
            body: JSON.stringify({
                email: email
            })
        }

        window.apiRequest('/contact/mailing_list', requestOptions, (result, status) => {   
            console.log(result)
            if (status === 200) {
                setResponse('Your email has been added successfully.')
                setEmail('')
            } else {
                setResponse('An error has occurred! Verify you have filled out all the required fields.')
            }
            setShowResponse(true) // Show response
            setTimeout(() => setShowResponse(false), 3000) // Hide after 3 seconds
        })
    }

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            subscribe(email)
        }
    }

    return (
        <div style={{ display: 'flex', flexDirection: window.innerWidth < 1550 ? 'column':'row', width: '100%', fontFamily: 'inter', color: window.colors.primary, ...props.style, textAlign: 'center'}}>
            {window.innerWidth > 1550 && <img src={Customers} style={{borderRadius: '20px', marginRight: 'auto', width: '48%', maxWidth: '950px'}} />}
            <div style={{display: 'flex', flexDirection: 'column', backgroundColor: window.colors.primary, borderRadius: '20px', marginLeft: 'auto', width: window.innerWidth < 1550 ? '90%':'48%', padding: '40px', boxSizing: 'border-box', maxWidth: window.innerWidth < 1550 && '950px', marginRight: window.innerWidth < 1550 && 'auto', minHeight: '400px', maxWidth: window.innerWidth < 1550 && '700px'}}>
                <span style={{fontWeight: '700', fontSize: window.innerWidth < 850 ? (window.innerWidth < 575 ? '26px':'40px'):'48px', color: window.colors.white, letterSpacing: '-1.5px'}}>Receive the latest deals as soon as they're released</span>
                <span style={{fontWeight: '400', fontSize: window.innerWidth < 850 ? (window.innerWidth < 575 ? '20px':'16px'):'24px', color: window.colors.muted, marginTop: '30px', marginBottom: '20px', maxWidth: '400px', margin: 'auto'}}>Subscribe to our email list to get new deals every month</span>
                <AnimatePresence>
                    {showResponse && (
                        <motion.span
                            initial={{ opacity: 0, y: -20 }}
                            animate={{ opacity: 1, y: 0 }}
                            exit={{ opacity: 0, y: -20 }}
                            transition={{ duration: 0.5 }}
                            style={{ marginTop: '0px', fontWeight: '500', fontSize: '16px', color: response.includes('error') ? 'red' : 'green'}}
                        >
                            {response}
                        </motion.span>
                    )}
                </AnimatePresence>
                <div style={{display: 'flex', flexDirection: 'row', marginTop: '50px', backgroundColor: window.colors.white, width: window.innerWidth < 800 ? '95%':'80%', alignContent: 'center', margin: 'auto', height: '36px', borderRadius: '5px', maxWidth: '350px'}}>
                    <input value={email} onChange={(e) => setEmail(e.target.value)} onKeyUp={handleKeyPress} placeholder='example@gmail.com' style={{backgroundColor: window.colors.white, border: 'none', flexGrow: 1, outline: 'none', marginLeft: '10px', fontFamily: 'inter', fontWeight: '500', width: '100%'}} />
                    <motion.button onClick={() => subscribe(email)} whileHover={window.bAnim} className='center' style={{backgroundColor: window.colors.primary, borderRadius: '3px', minWidth: '32px', height: 'auto', border: 'none', marginTop: 'auto', marginBottom: 'auto', marginRight: '3px', cursor: 'pointer', padding: '3px'}}>
                        <img src={Arrow} />
                    </motion.button>
                </div>


            </div>
        </div>
    )
}

export default Main
